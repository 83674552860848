<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <auto-multi v-if="!assocItem && isAuthorized( 'Worktime', 'validate' )" :title="'Utilisateur'" ressource="users" :listObject.sync="autoComplete.user"
            :listParent.sync="autoCompleteSource.user" :fields="{ 
                index: 'usr_id', text:'usr_middleName', search:'usr_firstName,usr_middlename', multiSelect:false,
                rounded: true, outlined: true, dense: true, class:'mt-8', 'background-color': 'white', prefetch: true, resultlimit: 'false',
                params: 'usr_role=ADMIN_USER,SUPER_USER,WORKSHOP_SUPERVISOR,WORKSHOP_LEADER,MECHANIC'
            }"
            @change="reloadTime()"
            class="ml-2 mr-2">
        </auto-multi>
    </v-toolbar>

    <v-toolbar dense class="ma-4 rounded-lg">
        <v-btn icon @click="getMondayOfCurrentWeek(-7)"><v-icon>mdi-arrow-left-bold-outline</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-icon>mdi-calendar</v-icon>&nbsp;
         Semaine du &nbsp;<b>{{monday.toLocaleDateString("fr")}}</b>&nbsp;au&nbsp;<b>{{monday.addDays(6).toLocaleDateString("fr")}}</b>
        <v-spacer></v-spacer>
        <v-btn icon @click="getMondayOfCurrentWeek(7)"><v-icon>mdi-arrow-right-bold-outline</v-icon></v-btn>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search" :items-per-page="-1"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" hide-default-footer>
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" class="mt-3" outlined rounded small color="green" @click="validateTime()">
                        Valider les temps<v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`header.week_1`]="{ header }">{{ header.text }} {{ week.lundi.getDate()    }} </template>
        <template v-slot:[`header.week_2`]="{ header }">{{ header.text }} {{ week.mardi.getDate()    }} </template>
        <template v-slot:[`header.week_3`]="{ header }">{{ header.text }} {{ week.mercredi.getDate() }} </template>
        <template v-slot:[`header.week_4`]="{ header }">{{ header.text }} {{ week.jeudi.getDate()    }} </template>
        <template v-slot:[`header.week_5`]="{ header }">{{ header.text }} {{ week.vendredi.getDate() }} </template>
        <template v-slot:[`header.week_6`]="{ header }">{{ header.text }} {{ week.samedi.getDate()   }} </template>
        <template v-slot:[`header.week_7`]="{ header }">{{ header.text }} {{ week.dimanche.getDate() }} </template>
        <template v-slot:[`header.action`]="{ header }">{{ header.text }}</template>

        <template v-slot:[`item.wti_act_id`]="{ item }">
            <v-chip @click="openSite(item.equ_id, item.wti_act_id)" class="ma-1" small outlined label :color="getCacheType('WTI', item.wti_typ_id).typ_color">
                #{{item.wti_act_id}} - {{ getCacheType('WTI', item.wti_typ_id).typ_name }}
            </v-chip><br/>
            <v-chip class="ma-1" small outlined label>
                {{item.cat_name}} / {{item.grp_name}}
            </v-chip>
        </template>
        <template v-slot:[`item.week_1`]="{ item }"><v-chip v-if="item.week_1 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_1 }}</v-chip><v-icon v-if="item.week_1_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_2`]="{ item }"><v-chip v-if="item.week_2 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_2 }}</v-chip><v-icon v-if="item.week_2_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_3`]="{ item }"><v-chip v-if="item.week_3 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_3 }}</v-chip><v-icon v-if="item.week_3_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_4`]="{ item }"><v-chip v-if="item.week_4 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_4 }}</v-chip><v-icon v-if="item.week_4_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_5`]="{ item }"><v-chip v-if="item.week_5 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_5 }}</v-chip><v-icon v-if="item.week_5_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_6`]="{ item }"><v-chip v-if="item.week_6 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_6 }}</v-chip><v-icon v-if="item.week_6_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_7`]="{ item }"><v-chip v-if="item.week_7 > 0" @click="openWorktime(item.wti_id, item.wti_act_id)" label outlined>{{ item.week_7 }}</v-chip><v-icon v-if="item.week_7_validated" small color="green">mdi-check</v-icon></template>
        <!-- <template v-slot:[`item.action`]="{ item }"><v-chip label outlined>{{ item.action }}</v-chip><v-icon @click.stop="validateTimeByUser(item)" color="green">mdi-check</v-icon></template> -->

        <template v-slot:foot>
            <tr style="background-color:#f2f2f2;">
                <td class="text-right">Total</td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_1 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_2 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_3 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_4 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_5 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_6 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_7 }}</v-chip></td>
            </tr>
        </template>
    </v-data-table>
    </v-card>
    <!-- Edit worktime -->
    <v-dialog v-model="modalWorktime" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Temps de travail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalWorktime', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-worktime class="elevation-2" 
                v-if="modalWorktime" :toast="toast" :switchModal="switchModal"
                :wti_id="wti_id" :act_id="act_id">
            </edit-worktime>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations, cacheData } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'
import editWorktime     from '../components/Edit_worktime.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'timesuser',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 25, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            modalWorktime: false,
            headers: [
            { text: 'Tache',     align: 'left', value: 'wti_act_id'},
            { text: 'lundi',     align: 'left',  value: 'week_1' },
            { text: 'mardi',     align: 'left',  value: 'week_2' },
            { text: 'mercredi',  align: 'left',  value: 'week_3' },
            { text: 'jeudi',     align: 'left',  value: 'week_4' },
            { text: 'vendredi',  align: 'left',  value: 'week_5' },
            { text: 'samedi',    align: 'left',  value: 'week_6' },
            { text: 'dimanche',  align: 'left',  value: 'week_7' },
            
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            monday: new Date(),
            week : { lundi: '', mardi: '', mercredi: '', jeudi: '', vendredi: '', samedi: '', dimanche: '' },
            actionsTime: {},
            totalTime: {},
            autoComplete: {
                user      : [],
            },
            autoCompleteSource:{
                user     : [],
            },
            assocItem: true,
            usrId:  0,
            wti_id: 0,
            act_id: 0,
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        user(){
            return cacheData.user
        },
    },
    beforeMount: function(){
        //if( this.$route.params.usr_id !== undefined && this.$route.params.usr_id > 0 ){
        //    this.usrId = parseInt(this.$route.params.usr_id)
        //}
        this.usrId    =  cacheGetters.getSearchBarItem('timesuser_usr_id') || 0
        this.usrId = parseInt(this.usrId)
        this.autoComplete.user = this.usrId
        let timesuser =  cacheGetters.getSearchBarItem('timesuser_wti_date_from')
        if( timesuser ){
            this.monday = new Date(timesuser)
        }

        this.getMondayOfCurrentWeek()
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('timesuser') || ''
        this.assocItem = false
    },
    methods: {
        getItems(){
            this.items       = []
            this.totalTime   = { week_1: 0, week_2: 0, week_3: 0, week_4: 0, week_5: 0, week_6: 0, week_7: 0 }
            this.actionsTime = {}
            let param     = '&order_by=wti_act_id ASC,wti_typ_id ASC&wti_usr_id=' + this.usrId
            param        += '&min_wti_date=' + this.monday.yyyymmdd() + '&max_wti_date=' + this.monday.addDays(6).yyyymmdd()
            //if( !this.isAuthorized( 'Worktime', 'validate' ) ){
            //    param  = "&usr_id=" + this.user.usr_id
            //}
            this.$http.get( '/worktimes?per_page=false&extended=byuser' + param ).then( (response) => {
                response.data.data.forEach(element => {
                    let key = element.wti_id //element.wti_act_id + '-' + element.wti_typ_id
                    this.actionsTime[key] = this.actionsTime[key] || element
                    if(!this.actionsTime[key]['durations']){
                        this.actionsTime[key]['durations'] = []
                    }
                    this.actionsTime[key]['durations'].push( element )
                })
                for( let prop in this.actionsTime ){
                    this.actionsTime[prop]['durations'].forEach( (element) => {
                        let wtiDate = new Date(element.wti_date)
                        let key = 'week_' + wtiDate.getDay()
                        this.actionsTime[prop][key] = parseFloat(element.wti_duration_sum)
                        if(this.totalTime[key] !== undefined){
                            this.totalTime[key] += parseFloat(element.wti_duration_sum)
                        } else {
                            this.totalTime[key] = 0
                        }
                        this.actionsTime[prop][key + '_validated'] = (element.wti_validated_min == 1) ? true : false
                    })
                    this.items.push(this.actionsTime[prop])
                }
            })
        },
        reloadTime(){
            this.usrId = this.autoComplete.user
            //this.$router.push({ path: '/timesuser/' + this.autoComplete.user })
            this.getItems()
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('timesuser', this.search)
        },
        getMondayOfCurrentWeek(increment) {
            increment = increment || 0
            this.monday = this.monday.addDays(increment)
            const today = new Date(this.monday)
            const first = today.getDate() - today.getDay() + 1

            const monday = new Date(today.setDate(first))
            this.week.lundi    = monday
            this.week.mardi    = monday.addDays(1)
            this.week.mercredi = monday.addDays(2)
            this.week.jeudi    = monday.addDays(3)
            this.week.vendredi = monday.addDays(4)
            this.week.samedi   = monday.addDays(5)
            this.week.dimanche = monday.addDays(6)

            this.monday = monday
            this.getItems()
        },
        validateTime(){
            let lundi    = this.week.lundi.yyyymmdd()
            let dimanche = this.week.dimanche.yyyymmdd()
            let message = 'Valider les heures du ' + lundi + ' au ' + dimanche
            this.$root.$confirm('Confirmez vous cette action ?', message, { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.put( '/worktimes/validatebyuser/' + this.usrId, {
                        usr_id: this.usrId,
                        wti_date_from: lundi,
                        wti_date_to: dimanche
                    } ).then( () => {
                        this.getItems()
                    })
                }
            })
        },
        openWorktime(wti_id, act_id){
            this.wti_id = wti_id
            this.act_id = act_id
            this.switchModal('modalWorktime', 1, false)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalWorktime'
            mode    = mode || 0
            refresh = (refresh !== false) ? true : false
            this[name] = mode

            if( refresh ){
                this.reloadTime()
            }
        },
        openSite(equ_id, act_id){
            this.$router.push({ path: '/timesuser/sheet/' + equ_id + '/action/' + act_id })
        },
        beforeDestroy() {

        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'auto-multi': autoCompMulti,
        'edit-worktime'   : editWorktime
    }
}
</script>