var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Liste des temps")]),_c('v-spacer'),((_vm.objAction.act_sta_id !== 'GEN03' || _vm.isAuthorized( 'Worktime', 'validate' )))?_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":(_vm.routeState.view) ? 'grey' : 'green'},on:{"click":function($event){return _vm.openWorktime(0)}}},[_vm._v(" Ajouter"),_c('v-icon',[_vm._v("mdi-playlist-edit")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem,"loading":_vm.loading.getWorktime},scopedSlots:_vm._u([{key:"item.usr_firstName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate font-weight-bold",staticStyle:{"max-width":"400px"},attrs:{"title":item.usr_lastName + ' ' + item.usr_firstName}},[_vm._v(" "+_vm._s(item.usr_lastName.substring(0,1) + '. ' + item.usr_firstName)+" ")])]}},{key:"item.wti_date",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.formatLocale( item.wti_date, 'fr' )))])]}},{key:"item.wti_duration",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":""}},[_vm._v(_vm._s(item.wti_duration))]),_vm._v("  "),((item.wti_typ_id == 'WTI01'))?_c('v-icon',{attrs:{"color":_vm.getCacheType('WTI', item.wti_typ_id).typ_color,"title":_vm.getCacheType('WTI', item.wti_typ_id).typ_name}},[_vm._v(" mdi-screw-machine-flat-top ")]):_c('v-icon',{attrs:{"color":_vm.getCacheType('WTI', item.wti_typ_id).typ_color,"title":_vm.getCacheType('WTI', item.wti_typ_id).typ_name}},[_vm._v(" mdi-home-city-outline ")])]}},{key:"item.wti_validated",fn:function(ref){
var item = ref.item;
return [((item.wti_validated))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.wti_id",fn:function(ref){
var item = ref.item;
return [((_vm.objAction.act_sta_id == 'GEN01') && !item.wti_validated && _vm.isAuthorized( 'Worktime', 'update' ))?_c('v-icon',{attrs:{"left":"","color":"orange","title":"Editer"},on:{"click":function($event){return _vm.openWorktime(item.wti_id)}}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{attrs:{"color":_vm.getCacheType('WTI', item.wti_typ_id).typ_color,"title":"Voir"},on:{"click":function($event){return _vm.openWorktime(item.wti_id)}}},[_vm._v(" mdi-eye ")])]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":( !_vm.loading.getWorktime ),"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucune saisie ")])],1)],2),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.modalLayer),callback:function ($$v) {_vm.modalLayer=$$v},expression:"modalLayer"}}),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.modalWorktime),callback:function ($$v) {_vm.modalWorktime=$$v},expression:"modalWorktime"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-toolbar-title',[_vm._v("Temps de travail")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){return _vm.switchModal('modalWorktime', 0)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[(_vm.modalWorktime)?_c('edit-worktime',{staticClass:"elevation-2",attrs:{"toast":_vm.toast,"switchModal":_vm.switchModal,"wti_id":_vm.wti_id,"act_id":_vm.objAction.act_id}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }