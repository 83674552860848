var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('v-menu',{ref:"datestart",staticStyle:{"z-index":"1500"},attrs:{"return-value":_vm.objTime.wti_date,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.objTime, "wti_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.objTime, "wti_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.locDateFormat.formatLocale( _vm.objTime.wti_date, 'fr' ),"label":"Date","prepend-icon":"mdi-calendar-today","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu.date_start),callback:function ($$v) {_vm.$set(_vm.dateMenu, "date_start", $$v)},expression:"dateMenu.date_start"}},[_c('v-date-picker',{attrs:{"locale":"fr-fr","no-title":"","scrollable":""},model:{value:(_vm.objTime.wti_date),callback:function ($$v) {_vm.$set(_vm.objTime, "wti_date", $$v)},expression:"objTime.wti_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dateMenu.date_start = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.datestart.save( _vm.objTime.wti_date )}}},[_vm._v("OK")])],1)],1)],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[(!_vm.assocItem)?_c('auto-multi',{staticClass:"ml-2 mr-2",attrs:{"title":'Agent',"ressource":"users","listObject":_vm.autoComplete.user,"listParent":_vm.autoCompleteSource.user,"fields":{ 
                    index: 'usr_id', text:'usr_firstName,usr_lastName', search:'usr_middleName', multiSelect:false 
                },"disabled":!_vm.isAuthorized( 'Worktime', 'validate')},on:{"update:listObject":function($event){return _vm.$set(_vm.autoComplete, "user", $event)},"update:list-object":function($event){return _vm.$set(_vm.autoComplete, "user", $event)},"update:listParent":function($event){return _vm.$set(_vm.autoCompleteSource, "user", $event)},"update:list-parent":function($event){return _vm.$set(_vm.autoCompleteSource, "user", $event)}}}):_vm._e()],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-select',{attrs:{"prepend-icon":"mdi-timetable","items":_vm.types,"item-text":"typ_name","item-value":"typ_id","label":"Statut","single-line":""},model:{value:(_vm.objTime.wti_typ_id),callback:function ($$v) {_vm.$set(_vm.objTime, "wti_typ_id", $$v)},expression:"objTime.wti_typ_id"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-clock-time-two-outline","label":"Temps de travail","type":"numeric","disabled":!(_vm.objTime.action.data.act_sta_id == 'GEN01' || _vm.isAuthorized( 'Worktime', 'validate' ))},model:{value:(_vm.objTime.wti_duration),callback:function ($$v) {_vm.$set(_vm.objTime, "wti_duration", $$v)},expression:"objTime.wti_duration"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.objTime.wti_created_at),expression:"objTime.wti_created_at"}],staticClass:"d-flex flex-column",attrs:{"label":"","outlined":""}},[_vm._v(" Création : "+_vm._s(_vm.locDateFormat.toLocale( _vm.objTime.wti_created_at, { year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' } ))+" ")])],1)],1)],1),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[(_vm.isAuthorized( 'Worktime', 'destroy' ) && (!_vm.objTime.wti_validated) && (_vm.objTime.wti_id > 0))?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 pa-0",staticStyle:{"min-width":"5px"},attrs:{"outlined":"","color":"grey"}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,502240912),model:{value:(_vm.optionMenu),callback:function ($$v) {_vm.optionMenu=$$v},expression:"optionMenu"}},[_c('v-btn',{staticClass:"ma-0",attrs:{"color":"error","disabled":_vm.saveProgress},nativeOn:{"click":function($event){return _vm.deleteWorktime()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can")]),_vm._v("Supprimer ")],1)],1):_vm._e(),_c('v-spacer'),((_vm.objTime.action.data.act_sta_id == 'GEN01' || _vm.isAuthorized( 'Worktime', 'validate' )) && (!_vm.objTime.wti_validated || _vm.isAuthorized( 'Worktime', 'updatevalidated' )))?_c('v-btn',{attrs:{"rounded":"","color":"success","loading":_vm.saveProgress,"disabled":!_vm.validForm},nativeOn:{"click":function($event){return _vm.saveWorktime()}}},[_vm._v(" Enregistrer ")]):_vm._e(),_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"grey"},on:{"click":function($event){return _vm.switchModal('modalWorktime', 0)}}},[_vm._v(" Fermer ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }