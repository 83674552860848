<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="downloadCurative()" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-download-outline</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Télécharger la liste</span>
        </v-tooltip>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table ref="sheetTable" v-bind:headers="headers" v-bind:items="listAction" v-bind:search="search" @current-items="getFilteredItems"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="3" md="3" class="text-right pa-1">
                    <AutocompleteMulti :title="'Catégorie'" ressource="categories" :listObject.sync="filter.categories"
                        :listParent.sync="filterSource.categories" @change="getCurative"
                        :fields="{ index: 'cat_id', text:'cat_name', search:'cat_name', preprendIcon:'mdi-filter', prefetch:true, multiSelect:true }" />
                </v-col>
                <v-col cols="3" md="3" class="text-right pa-1">
                    <AutocompleteMulti :title="'Dépots'" ressource="stores" :listObject.sync="filter.stores"
                        :listParent.sync="filterSource.stores" @change="getCurative"
                        :fields="{ index: 'str_id', text:'str_name', search:'str_name', preprendIcon:'mdi-filter', prefetch:true, multiSelect:true }" />
                </v-col>
            </v-row>
        </template>
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.pre_id" @click="openSite(item.act_equ_id, item.act_id)" style="cursor:pointer;">
            <td class="text-xs-left">{{ locDateFormat.formatLocale( item.act_date_start, 'fr') }}</td>
            <td class="text-xs-left">{{ locDateFormat.formatLocale( item.act_date_end, 'fr') }}</td>
            <td class="text-xs-left">
                <v-icon small>{{ ( (item.act_usage_distance !== 0) ? 'mdi-map-marker-distance' : 'mdi-clock-outline' ) }}</v-icon>
                {{ ( (item.act_usage_distance !== 0) ? item.act_usage_distance : item.act_usage_hour ) }}
            </td>
            <td class="text-xs-left">
                <v-chip small outlined label :color="getCacheState('GEN', item.act_sta_id).sta_color">{{ getCacheState('GEN', item.act_sta_id).sta_label }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip small outlined label :color="getCacheType('ACT', item.act_typ_id).typ_color">{{ getCacheType('ACT', item.act_typ_id).typ_name }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip small outlined label :color="getCacheType('ACK', item.act_typ_id_kind).typ_color">{{ getCacheType('ACK', item.act_typ_id_kind).typ_name }}</v-chip>
            </td>
            <td class="text-xs-left">{{ item.ent_name }}</td>
            <td class="text-xs-left">{{ item.equ_code }}</td>
            <td class="text-xs-left">{{ item.cat_name }}</td>
            <td class="text-xs-left">{{ item.bmo_code }}</td>
            <td class="text-xs-left">{{ item.grp_name }}</td>
            <td class="text-xs-left">
                <v-chip small outlined label color="grey">
                    <v-icon small>mdi-barcode-scan</v-icon>&nbsp;{{ item.equipment.data.equ_customfields.equ_registration }}
                </v-chip>
            </td>
        </tr>
        </tbody>
<!--        <tfoot style="background-color:#E3F2FD;">
            <tr>
                <td></td><td></td><td></td><td></td><td></td><td></td>
                <td class="text-xs-left">{{ 0 }}</td>
                <td></td>
            </tr>
        </tfoot>
-->
        </template>
        <template slot="no-data">
        <v-alert :value="true" color="error" icon="mdi-alert">
            Aucune fiche
        </v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span v-show="pagination.totalFiltered">{{pagination.totalFiltered}} sur </span>
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { actObj }  from '../mixins/act_obj.js'

import { usrGetters }   from "../store/user.module"
import { filterUpdateService } from '@/rxjsServices';
import AutocompleteMulti from '@/components/Autocomplete_multi'

export default {
    name: 'curative',
    props: ['toast', 'filterView', 'contextStyle'],
    mixins: [ actObj ],
    components: {
        AutocompleteMulti
    },
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 10, totalPage: 1, limit: 0, pageCount: 0, totalFiltered: 0 },
            headers: [
            { text: 'Début', align: 'left', value: 'act_date_start'},
            { text: 'Fin',   align: 'left', value: 'act_date_end'},
            { text: 'Index',              align: 'left', value: 'act_usage_hour'},
            { text: 'Etat',                              value: 'act_sta_id' },
            { text: 'Prestation',               value: 'act_typ_id' },
            { text: 'Nature',             align: 'left', value: 'act_typ_id_kind' },
            { text: 'Prestataire',        align: 'left', value: 'ent_name' },
            { text: 'Machine',            align: 'left', value: 'equ_code' },
            { text: 'Cat.',               align: 'left', value: 'cat_name' },
            { text: 'Modèle',             align: 'left', value: 'bmo_code' },
            { text: 'Groupe',             align: 'left', value: 'grp_name' },
            { text: 'Immat.',             align: 'left', value: 'equ_customfields.equ_registration' },
            ],
            items: [],
            filteredItems: [],
            locDateFormat: dateLocale, // Format de date
            enableAdd: true,
            modalEvent : false,
            isAuthorized: usrGetters.getScopeRight,
            filter: {
                categories: [],
                stores :[],
            },
            filterSource:{
                categories :[],
                stores :[],
            },
        }
    },
    watch: { },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('curative') || ''
        this.getCurative()
    },
    created(){
        this.subFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getCurative()
            }
        })        
    },
    methods: {
        async getCurative(){
            let param = ""
            if( this.filter.categories.length ){
                param += '&cat_id=' + this.filter.categories.join(',')
            }
            if( this.filter.stores.length ){
                param += '&str_id=' + this.filter.stores.join(',')
            }
            await this.getActionIndex('act_typ_id=ACT01&extended=withequipment,withcategory,withbrand,withbrandmodel,withgroup,withentity,withstore&include=equipment' + param)
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        openSite(equ_id, act_id){
            this.$router.push({ path: '/curative/sheet/' + equ_id + '/action/' + act_id })
        },
        downloadCurative(){
            let searchCriteria = this.getActionFilter()
            this.$http.customRequest({ method: 'get', url: '/report/actions/' + searchCriteria + '&act_typ_id=ACT01', responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport_1.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getPreventive()
        },
        getFilteredItems(e){
            let totalFiltered = e.length
            if( totalFiltered >= this.pagination.itemsPerPage ){
                this.pagination.totalFiltered = 0
            } else {
                this.pagination.totalFiltered = totalFiltered
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('preventive', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    beforeDestroy() {
        this.subFilter.unsubscribe()
    }
}
</script>

<style lang="css">
.v-pagination{
    width: 450px;
}
</style>