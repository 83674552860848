<template>
<v-container fluid class="pa-0 ma-0">
<v-card style="z-index:1100;">
<v-layout row wrap>
    <v-flex xs12 class="pa-2">
        <v-list class="pb-0">
            <v-list-item 
                v-for="item in items" :key="item.equ_id" 
                class="pb-0" @click="menuState(false)" :href="'/#/dashboard/sheet/' + item.equ_id ">
                <v-list-item-avatar>
                    <v-icon large :color="(item.equ_id == openedSheetId) ? 'green' : 'grey'">mdi-file-eye</v-icon>
                </v-list-item-avatar>
                <v-list-item-content style="border-bottom: 1px solid #CFD8DC">
                    <v-list-item-title :style="'color:' + item.sta_color + ';'">
                        {{ item.equ_name || item.equ_code }} <span class="caption grey--text">({{ item.equ_serial }})</span>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip small outlined label light :color="actionType['TRA'][item.equ_typ_id_trade].typ_color">
                        {{ actionType['TRA'][item.equ_typ_id_trade].typ_name }}
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-list class="pb-0" v-if="!itemsCount">
            <v-list-item>
                <v-list-item-content style="border-bottom: 1px solid #CFD8DC">
                    <v-list-item-title style="color:orange">
                        Aucune fiche en favoris
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-flex>
</v-layout>
</v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheData } from "../store/cache.module"

export default {
    name: 'view-open',
    props: ['filterDiff', 'menuState', 'tabsheetCount'],
    data: function () {
        return {
            saveProgress : false,
            locDateFormat: dateLocale, // Format de date
            actionType   : cacheGetters.getTypes(),
            menu         : false,
            //items: {}
        }
    },
    computed: {
        openedSheetId(){
            if( this.$route.params.equ_id !== undefined && this.$route.params.equ_id > 0 ){
                return this.$route.params.equ_id
            } else {
                return false
            }
        },
        items() {
            return cacheData.bookmark
        },
        itemsCount(){
            var count = 0
            for(var prop in cacheData.bookmark) {
                if( prop.length > 0 ){
                    ++count
                }
            }
            return count
        }
    },
    methods: { },
    components: { }
}
</script>