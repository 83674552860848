<template>
  <v-app id="app" class="global-app">
    <v-navigation-drawer 
      width="250" style="z-index:900 !important;"
      mobile-breakpoint="600" fixed
      :mini-variant="miniVariant" :clipped="clipped" v-model="drawer" app hide-overlay dark color="#0A2A35">
      <v-toolbar flat dense short dark color="#0A2A35" :class="(miniVariant) ? 'mb-5 toolbar-minivariant' : 'mb-5'" >
        <v-list dark class="pa-0" style="width: 100%;">
          <v-list-item dark class="pa-0">
            <v-list-item-icon style="margin-right: 25px;">
              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                    <img v-on="on" class="hidden-sm-and-up" height="35" width="40" @click.stop="drawer = !drawer" src="../assets/logo_small.svg" style="cursor:pointer;" />
                    <img v-on="on" class="hidden-xs-only"   height="35" width="40" @click.stop="miniVariant = !miniVariant" src="../assets/logo_small.svg" style="cursor:pointer; margin-right: 0px;" />
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><b>MATERIEL</b></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
            <v-tooltip bottom open-delay="1500">
                <template v-slot:activator="{ on }">
                    <v-btn :ripple="false" text v-on="on" @click.stop="switchMenu()" elevation="3"
                          fab small dark style="background-color:#0C3644;">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <span>Ouvrir / fermer le menu</span>
            </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-list dark dense rounded>
        <template v-for="(item, i) in items" >
          <v-list-group v-if="item.active && item.items" :key="i" v-bind:group="item.group" :prepend-icon="item.icon" 
          :style="(miniVariant) ? 'padding: 0 8px; width:100%;' : 'width:100%;'" :class="(miniVariant) ? 'minivariant-list-group' : ''">
            <template v-slot:activator>
            <v-subheader v-if="item.header && !miniVariant" :key="i" class="pl-0 theme--dark" style="margin-right:auto;">
              {{ item.header }}
            </v-subheader>
            <v-list-item v-else-if="!miniVariant" :to="item.href" :title="item.title" class="pa-0">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-subheader v-else :title="item.title" class="pl-0 theme--dark">
              <v-icon v-html="item.icon" dark></v-icon>
            </v-subheader>
            </template>
            <!-- Sous menus -->
            <v-list-item v-for="subItem in item.items" :key="subItem.href" :to="subItem.href" :title="subItem.title"
                        v-bind:router="!subItem.target" ripple
                        v-bind:disabled="subItem.disabled" v-bind:target="subItem.target" 
                        :style="(miniVariant) ? 'padding: 0 0px;' : ''">
              <v-list-item-action v-if="subItem.active && subItem.icon" >
                <v-icon v-html="subItem.icon" dark></v-icon>
              </v-list-item-action>
              <v-list-item-content v-if="subItem.active">
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- Divider -->
          <v-divider v-else-if="item.active && item.divider" :key="i"></v-divider>
          <!-- Sous titre -->
          <v-subheader v-else-if="item.active && item.header && !miniVariant" :key="i" class="theme--dark">{{ item.header }}</v-subheader>
          <v-list-item v-else-if="item.active && !item.header" :key="i" :to='item.href' :title="item.title" 
                  :style="(miniVariant) ? 'padding: 0 8px;' : ''">
            <v-list-item-action>
              <v-icon v-html="item.icon" dark></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense color="white" light short elevation="1" style="z-index:100 !important;">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="pt-1 pr-1 hidden-sm-and-up" @click.stop="drawer = !drawer">mdi-chevron-right </v-icon>
        </template>
        <span>Ouvrir / fermer le menu</span>
      </v-tooltip>
      <v-menu v-model="filterMenu" :close-on-content-click="false" :nudge-width="300" offset-y z-index="900" rounded="rounded-lg" content-class="rounded-lg">
        <template v-slot:activator="{ on: filterMenu }">
          <v-tooltip right open-delay="1500" >
              <template v-slot:activator="{ on: tooltip }">
                <v-badge :content="filterCount" :value="filterCount" color="red" overlap >
                  <v-btn text v-on="{ ...tooltip, ...filterMenu }">
                      <v-icon >mdi-filter-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span>Filtre général</span>
          </v-tooltip>
        </template>
        <view-filter style="z-index:900 !important;" :filterDiff="filterDiff" :filterCount.sync="filterCount" :filterView.sync="filterMenu"></view-filter>
      </v-menu>
      <v-spacer></v-spacer>
      <v-menu v-model="alertMenu" :close-on-content-click="false" :nudge-width="250" offset-y z-index="1100">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom open-delay="1500">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator" v-show="alertBelt">
                  <v-badge color="red" class="mt-1" :content="alertCount" :value="alertCount">
                    <v-icon>mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Liste des alertes</span>
          </v-tooltip>
        </template>
        <view-openalert class="mt-2" style="z-index:900 !important;" :filterDiff="filterDiff" :menuState="openCardState"></view-openalert>
      </v-menu>
      <!-- Versionning -->
      <v-btn text style="text-transform: capitalize;" @click="dialChangelog = !dialChangelog">
        <v-icon left >mdi-information-outline</v-icon>
        {{ $APP_VER }}
      </v-btn>
      <!-- User action -->
      <v-menu v-model="userMenu" offset-y transition="slide-x-transition" z-index="902" rounded="rounded-lg" content-class="rounded-lg">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" style="text-transform: capitalize;">
            <v-avatar><v-icon dark class="ml-0">mdi-account-circle</v-icon></v-avatar>
            {{user.usr_firstName}}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item link :href="'/#/users/'">
            <v-list-item-icon><v-icon medium>mdi-account-outline</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title v-text="'Mon profil'"></v-list-item-title></v-list-item-content>
          </v-list-item>
          <v-list-item link @click="logout()">
            <v-list-item-icon><v-icon color="red">mdi-logout-variant</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title v-text="'Se déconnecter'"></v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Router view page content -->
    <v-main v-if="dataReady" style="background-color:#E5E5E5; position: relative;">
      <router-view :toast="toast" :filterView="filterMenu" :contextStyle="contextStyle"></router-view>
    </v-main>
    <!-- Router view slider -->
    <router-view v-if="dataReady" name="modal" :toast="toast" :siteTabState="siteTabState" :filterView="filterMenu"></router-view>
    <confirm ref="confirm"></confirm>
    <view-changelog :diagState.sync="dialChangelog" style="z-index:2000;"></view-changelog>
    <v-snackbar style="z-index:2000;"
      :timeout="2000" :color="toastOption.color"
      :top="toastOption.top" :bottom="toastOption.bottom" 
      :left="toastOption.left" :right="toastOption.right" v-model="enableToast" >
        {{toastOption.text}}
    </v-snackbar>
  </v-app>
</template>

<script>
import { actions } from "../store/auth.module"
import menu         from '../confnav'
import { cacheData, cacheMutations, cacheGetters, cacheActions } from "../store/cache.module"
import vFilter      from '../components/View_filter.vue'
//import vOpencard    from '../components/View_openedcard.vue'
//import vOpenalert   from '../components/View_alert.vue'
import vChangelog   from '../components/View_changelog.vue'
import confirmDial  from '../components/Confirm_dialog.vue'
import { scopeMutations } from "../scopeCredential"

export default {
  name: 'mainApp',
  //props: ['msg'],
  data () {
    return {
        clipped: false,
        drawer: false,
        fixed: false,
        items: [],
        miniVariant: false,
        right: true,
        rightDrawer: false,
        title: process.env.VUE_APP_TITLE,
        option: {},
        enableToast: false,
        toastDefault: { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
        toastOption:  { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
        filterMenu: false, 
        siteMenu: false, 
        siteTabBt: true,
        alertBelt: false,
        userMenu: false,
        alertMenu: false,
        dataReady: false,
        filterCount: 0,
        filterDiff: 0,
        alertCount: 0,
        tabsheetCount: 0,
        dialChangelog: false,
        menuByRoute: {}
    }
  },
  computed: {
    contextStyle(){
      let objStyle = {
          title: '', icon: '',
          titleClass: 'pageTitleColor text-h5 font-weight-bold d-flex align-end', titleColor:'#005d7f', 
          titleIcon: 'pr-2',
          basePath: '',
          pathTab: [],
          breadcrumbs: []
      }
      let pathTab = this.$route.path.split('/')
      let path    = '/' + pathTab[1]
      if( this.menuByRoute[path] ){
        objStyle.title    = this.menuByRoute[path].title
        objStyle.icon     = this.menuByRoute[path].icon
        objStyle.basePath = path
        objStyle.pathTab  = pathTab
        objStyle.breadcrumbs.push(
          { text: this.menuByRoute[path].title, disabled: false, href: '/#' + objStyle.basePath }
        )
        objStyle.breadcrumbs.push(
          { text: 'fiche', disabled: true, href: objStyle.basePath }
        )
      }
      return objStyle
    },
    user(){
      return cacheData.user
    },
    bookmarkCount(){
        var total = 0
        var item
        for( item in cacheData.bookmark ){
            if( item ){
                total++
            }
        }
        return total
    }
  },
  beforeMount: function(){
    //Chargement du cache
    cacheActions.initCacheFromStorage().then( () => {
        Promise.all([ 
          this.getType(), this.getState(), this.getUserInfo(), this.getTaskType(), this.getGroup()
        ]).then( () => {
          cacheMutations.setStates( cacheGetters.getStates() )
          cacheMutations.setTypes( cacheGetters.getTypes() )
          cacheMutations.setUser( cacheGetters.getUser() )
          cacheMutations.setTaskType( cacheGetters.getTaskType() )
          cacheMutations.setGroup( cacheGetters.getGroup() )
          cacheActions.storeCacheToStorage() //this.$storage.localStorage.set( this.$APP_COD + "_cache", cacheData)
        }).then( () => {
          this.buildMenu()
          this.dataReady = true
          if( this.$route.path == '/forbidden' ){
            this.$router.push('/dashboard')
          }
        }).catch( () => {
          this.buildMenu()
          this.dataReady = true
        })
    })
  },
  mounted: function (){
    // Global Confirm Dialog
    this.$root.$confirm = this.$refs.confirm.open
  },
  methods: {
    buildMenu(){
      // Right on menu by role
      let usrRole  = cacheGetters.getUserRole()
      menu.menu.forEach( (element) => {
        element.active = true
        if( element.href ){ this.menuByRoute[element.href] = element } // for pages
        if( element.role !== undefined && element.role.indexOf( usrRole ) === -1 ){
            element.active = false //menu.menu.splice( menu.menu.indexOf(element), 1 )
        } else if( element.items !== undefined ){
          element.items.forEach( (item) => {
            item.active = true
            if( item.href ){ this.menuByRoute[item.href] = item } // for pages
            if( item.role !== undefined && item.role.indexOf( usrRole ) === -1 ){
                item.active = false
                element.items.splice( element.items.indexOf( item ), 1 )
            }
          })
        }
      })
      this.items = menu.menu
      //this.items = ctrlMenu
      if( window.innerWidth > 600 ){
        this.miniVariant = false
        this.drawer      = true
      }
    },
    getType () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/types?per_page=false' ).then( (response) => {
          let types = {}
          response.data.data.forEach( (item) => {
            if( types[ item.typ_id.substring(0, 3) ] == undefined ){
              types[ item.typ_id.substring(0, 3) ] = {}
            }
            types[ item.typ_id.substring(0, 3)][item.typ_id] = item
          })
          cacheMutations.setTypes( types )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getState () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/states?per_page=false' ).then( (response) => {
          let states = {}
          response.data.data.forEach( (item) => {
            if( states[ item.sta_id.substring(0, 3) ] == undefined ){
              states[ item.sta_id.substring(0, 3) ] = {}
            }
            states[ item.sta_id.substring(0, 3) ][item.sta_id] = item
          })
          cacheMutations.setStates( states )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getUserInfo(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/users/me/?include=useraccess' ).then( (response) => {
          cacheMutations.setUser( response.data.data )
          if( response.data.data && response.data.data.useraccess ){
            scopeMutations.setAccess( response.data.data.useraccess.data.uac_access_json )
          }
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getTaskType () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/tasktypes?per_page=false' ).then( (response) => {
          cacheMutations.setTaskType( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getGroup () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/groups?per_page=false' ).then( (response) => {
          cacheMutations.setGroup( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    toast (option) {
      if( option !== null && typeof option === 'object' ){
        var prop
        for( prop in this.toastDefault ){
            if( option[prop] !== undefined ){
                this.toastOption[prop] = option[prop]
            }
        }
      }
      this.enableToast = true
    },
    hideToast () {
      this.snackbar = false
    },
    updateFilter(num){
      this.filterDiff = num
    },
    openCardState(state){
      this.siteMenu = state
    },
    siteTabState(state){
      this.siteTabBt = state
    },
    logout(){
      if( process.env.VUE_APP_OAUTH_IDP === 'true' ){
        actions.logout().then( () => {
          this.$router.push({ path: '/login' })
        })
      } else {
        actions.logout().then( () => {
          const uri = `?returnTo=${encodeURIComponent( process.env.VUE_APP_URL + '#/login' )}`
          let samlIpd = process.env.VUE_APP_SAML_IDP || 'test'
          window.location = process.env.VUE_APP_API_URL + 'saml2/' + samlIpd + '/logout' + uri
        })
      }
    },
    switchMenu(){
      if( this.$vuetify.breakpoint.width < 600 ){
        this.drawer     = !this.drawer
      } else {
        this.miniVariant = !this.miniVariant
      }
    }
  },
  components: {
    'view-filter'   : vFilter,
//    'view-opencard' : vOpencard,
    'confirm' : confirmDial,
    'view-changelog' : vChangelog
  }
}
</script>
