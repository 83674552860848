<template>
<v-container fluid>
    <v-card outlined fluid class="pa-5">
        <v-layout v-if="loading.getgetTaskItems" row wrap>
        <template v-for="(item, index) in taskItems">
            <v-flex xs4 class="pa-1" :key="index">
                <auto-multi :title="selectFields[index].title" 
                    :listParent="item" :fields="selectFields[index]" :listObject.sync="taskSelected[index]" :disabled="disabledField">
                </auto-multi>
            </v-flex>
        </template>

        <v-flex xs12 class="pa-1">
            <v-textarea outlined rows="2" name="input-7-1" label="Commentaire" v-model="objAction.act_observation" hint="Commentaire" :disabled="disabledField"></v-textarea>
        </v-flex>
        </v-layout>
        <!-- <div class="text-right"><v-btn color="success" @click.native="saveForm()">Enregistrer</v-btn></div> -->
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"
import autoCompMulti    from './Autocomplete_multi.vue'

export default {
    name: 'site_edit',
    props: ['toast', 'objAction', 'routeState'], 
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            locDateFormat: dateLocale, // Format de date
            taskItems    : {},
            taskSelected : {},
            selectFields : {},
            actionTask   : [],
            typeState: cacheGetters.getTypes(),
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {
        disabledField(){
            return (this.objAction.act_sta_id !== 'GEN03' || this.isAuthorized( 'Action', 'updateValidated' )) ? false : true
        },
    },
    beforeMount: function(){
        this.getTaskWithAction()
    },
    mounted: function (){
        this.$eventBus.$on('saveaction', this.saveForm )
    },
    methods: {
        async getTaskWithAction(){
            await this.getActionTasks()
            await this.getTaskItems()
        },
        getActionTasks(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/actiontasks/?per_page=false&atk_act_id=' + this.objAction.act_id ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        response.data.data.forEach(element => {
                            this.actionTask.push( parseInt(element.atk_tty_id) )
                        })
                        resolve('resolved')
                    } else {
                        resolve('resolved')
                    }
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTaskItems(){
            return new Promise( (resolve, reject) => {
                let response = { data : { data: cacheGetters.getTaskType() } }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        if( this.taskItems[element.tty_typ_id] === undefined ){
                            this.$set(this.taskItems, element.tty_typ_id, [])
                            this.$set(this.taskSelected, element.tty_typ_id, [])
                            this.$set(
                                this.selectFields, element.tty_typ_id, 
                                {
                                    "title": this.getCacheType('TSK', element.tty_typ_id).typ_name,
                                    "index":"tty_id","text":"tty_name","preprendIcon":undefined,
                                    "autoselectfirst":false,"params":"", "outlined": true
                                }
                            )
                            
                        }
                        if( this.actionTask.includes( parseInt(element.tty_id) ) ){
                            this.taskSelected[ element.tty_typ_id ].push( element.tty_id )
                        }
                        this.taskItems[element.tty_typ_id].push(element)
                    })
                    this.loading.getgetTaskItems = true
                    resolve('resolved')
                } else {
                    reject('error')
                }
            })
        },
        saveForm(){
            return new Promise( (resolve, reject) => {
                let actionTasktype = []
                for( let prop in this.taskSelected ){
                    this.taskSelected[prop].forEach(element => {
                        actionTasktype.push( element )
                    })
                }
                this.$http.put( '/actions/' + this.objAction.act_id, { 'actiontask': actionTasktype, 'act_observation': this.objAction.act_observation } ).then( (response) => {
                    this.item = response.data.data
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>