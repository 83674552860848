<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="downloadPreventive()" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-download-outline</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Télécharger la liste</span>
        </v-tooltip>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table ref="sheetTable" :headers="headers" :items="items" v-bind:search="search" @current-items="getFilteredItems"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1"></v-col>
            </v-row>
        </template>
        <template v-slot:[`item.bmo_typ_id_energy`]="{ item }">
            <v-chip small outlined label :color="getCacheType('ENY', item.bmo_typ_id_energy).typ_color">{{ getCacheType('ENY', item.bmo_typ_id_energy).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.equ_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/preventive/sheet/' + item.equ_id + '/action/0/ACT02/ACK01'">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Créer
            </v-btn>
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/preventive/sheet/' + item.equ_id + ''">
                <v-icon left class="mr-2">mdi-eye</v-icon>Historique
            </v-btn>
        </template>
        <template slot="no-data">
        <v-alert :value="true" color="error" icon="mdi-alert">
            Aucune fiche
        </v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span v-show="pagination.totalFiltered">{{pagination.totalFiltered}} sur </span>
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { actObj }  from '../mixins/act_obj.js'

import { usrGetters }   from "../store/user.module"

export default {
    name: 'curative',
    props: ['toast', 'filterView', 'contextStyle'],
    mixins: [ actObj ],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 10, totalPage: 1, limit: 0, pageCount: 0, totalFiltered: 0 },
            headers: [
            { text: 'Machine',  align: 'left',    value: 'equ_code'},
            { text: 'N.Serie',  align: 'left',    value: 'equ_serial'},
            { text: 'Modèle',   align: 'left',                value: 'bmo_code'},
            { text: 'Energie',                    value: 'bmo_typ_id_energy' },
            { text: 'Catégorie', align: 'left',             value: 'cat_name' },
            { text: 'Maintenance', align: 'left',value: 'equ_maintenance_at' },
            { text: 'Action', align: 'left',value: 'equ_id' },
            ],
            items: [],
            filteredItems: [],
            locDateFormat: dateLocale, // Format de date
            enableAdd: true,
            modalEvent : false,
            isAuthorized: usrGetters.getScopeRight,
        }
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                this.getPreventive()
            }
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('curative') || ''
        this.getPreventive()
    },
    methods: {
        getPreventive(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/provisional/equipments' ).then( (response) => {
                    this.items = response.data.data
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        openSite(equ_id){
            this.$router.push({ path: '/preventive/sheet/' + equ_id })
        },
        downloadPreventive(){
            let searchCriteria = this.getActionFilter()
            this.$http.customRequest({ method: 'get', url: '/report/actions/' + searchCriteria + '&act_typ_id=ACT02', responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport_1.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getPreventive()
        },
        getFilteredItems(e){
            let totalFiltered = e.length
            if( totalFiltered >= this.pagination.itemsPerPage ){
                this.pagination.totalFiltered = 0
            } else {
                this.pagination.totalFiltered = totalFiltered
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('preventive', this.search)
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        
    }
}
</script>

<style lang="css">
.v-pagination{
    width: 450px;
}
</style>