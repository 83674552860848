<template>
<v-container fluid>
    <v-card fluid outlined class="pa-5">
        <v-layout row wrap>
        <v-flex xs8 class="pa-2">
            <v-text-field label="Description" prepend-icon="mdi-script-text-outline" v-model="objAction.act_description" :disabled="disabledField" ></v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <auto-multi v-if="!assocItem" :title="'Réalisation'" ressource="entities" :listObject.sync="objAction.act_ent_id"
                :listParent.sync="autoCompleteSource.entity" :fields="{ index: 'ent_id', text:'ent_name', 
                search:'ent_name', multiSelect:false, prefetch: true }" :disabled="disabledField"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-menu ref="datestart" v-model="dateMenu.date_start"
                :return-value.sync="objAction.eve_date_start"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objAction.act_date_start, 'fr' )"
                        label="Date de début travaux" prepend-icon="mdi-calendar-today" readonly v-on="on" :disabled="disabledField">
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objAction.act_date_start">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_start = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datestart.save( objAction.act_date_start )">OK</v-btn>
                </v-date-picker>
            </v-menu>
            <v-select prepend-icon="mdi-car-wrench" :items="actTyp" item-text="typ_name" item-value="typ_id" v-model="objAction.act_typ_id" label="Type" single-line :disabled="disabledField"></v-select>
            <v-text-field prepend-icon="mdi-timer-outline" :label="'Index heure (' + objEquipment.equ_usage_hour + ')'"  v-model="objAction.act_usage_hour" type="number" :disabled="disabledField"></v-text-field>
            <v-text-field prepend-icon="mdi-currency-eur" :label="'Main d\'œuvre HT'" v-model="objAction.act_value" type="number" :disabled="disabledField"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-menu ref="dateend" v-model="dateMenu.date_end"
                :return-value.sync="objAction.act_date_end"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objAction.act_date_end, 'fr' )"
                        label="Date de fin travaux" prepend-icon="mdi-calendar-today" readonly v-on="on" :disabled="disabledField">
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objAction.act_date_end">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_end = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.dateend.save( objAction.act_date_end )">OK</v-btn>
                </v-date-picker>
            </v-menu>
            <v-select prepend-icon="mdi-progress-wrench" :items="actTypKind" item-text="typ_name" item-value="typ_id" v-model="objAction.act_typ_id_kind" label="Nature prestation" single-line :disabled="disabledField"></v-select>
            <v-text-field prepend-icon="mdi-counter" :label="'Index km (' + objEquipment.equ_usage_distance  + ')'"  v-model="objAction.act_usage_distance" type="number" :disabled="disabledField"></v-text-field>
            <v-menu ref="datedueon" v-model="dateMenu.date_dueon"
                :return-value.sync="objAction.eve_date_dueon"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objAction.act_date_dueon, 'fr' )"
                        label="Date prévisionnelle" prepend-icon="mdi-calendar-today" readonly v-on="on" :disabled="disabledField">
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objAction.act_date_dueon">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_dueon = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datedueon.save( objAction.act_date_dueon )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-textarea rows="2" prepend-icon="mdi-comment" label="Commentaire"    v-model="objAction.act_observation" :disabled="disabledField"></v-textarea>
        </v-flex>
        </v-layout>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'site_edit',
    props: ['toast', 'objAction', 'routeState', 'objEquipment'], 
    data: function () {
        return {
            form: { pre_nature: 0, pre_artht: 25 },
            loading: { getVersion: false },
            validFrom: true,
            locDateFormat: dateLocale, // Format de date
            dateMenu: { date_start:false, date_end:false, date_dueon:false },
            actTyp: cacheGetters.getTypes('ACT'),
            actTypKind: cacheGetters.getTypes('ACK'),
            autoComplete: {
                //entity     : [],
            },
            autoCompleteSource:{
                entity     : [],
            },
            assocItem: false,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {
        disabledField(){
            return (this.objAction.act_sta_id !== 'GEN03' || this.isAuthorized( 'Action', 'updateValidated' )) ? false : true
        },
    },
    mounted: function (){
        if( this.objAction._info.entity.ent_id ){
            this.autoCompleteSource.entity.push( { ent_name: this.objAction._info.entity.ent_name, ent_id: this.objAction._info.entity.ent_id } )
        }

        if( this.$route.params.act_id !== undefined && this.$route.params.act_id == 0 ){
            if( this.$route.params.act_typ_id !== undefined && this.$route.params.act_typ_id.length == 5  ){
                this.objAction.act_typ_id = this.$route.params.act_typ_id
            }
            if( this.$route.params.act_typ_id_kind !== undefined && this.$route.params.act_typ_id_kind.length == 5  ){
                this.objAction.act_typ_id_kind = this.$route.params.act_typ_id_kind
            }
        }
    },
    methods: {
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>