<template>
<v-dialog v-model="modalState" max-width="1000" @keydown.esc="modalState = false" :persistent="false">
    <v-card style="z-index:1100;">
    <v-toolbar color="primary" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>Résumé des modifications de version</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="modalState = false"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text >
        <v-sheet class="overflow-y-auto" max-height="600">
        <v-expansion-panels class="mt-3 mb-6" focusable >
        <v-expansion-panel v-for="(item,i) in items" :key="i" >
            <v-expansion-panel-header expand-icon="mdi-menu-down" disable-icon-rotate>
                V. {{ item.ver_version }}
                <template v-slot:actions>
                    <v-chip class="mr-2" small color="grey" outlined><v-icon left>mdi-wrench</v-icon>{{ item.ver_changelog.length }}</v-chip>
                    <v-chip class="mr-2" small color="grey" outlined><v-icon left>mdi-calendar</v-icon>{{ locDateFormat.formatLocale( item.ver_date, 'fr' ) }}</v-chip>
                    <v-icon :color="item.ver_color">mdi-{{item.ver_icon}}</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <template v-for="(report, index) in item.ver_changelog">
                    <v-list-item two-line :key="index">
                    <v-list-item-content>
                        <v-list-item-title>{{report.title}}</v-list-item-title>
                        <v-list-item-subtitle>{{report.description}}</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                </template>
            </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
        </v-sheet>
    </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import dateLocale from '../services/dateLocale';

export default {
    name: 'view-filter',
    props: ['diagState'],
    data: function () {
        return {
            saveProgress: false,
            locDateFormat: dateLocale, // Format de date
            state : {},
            items: []
        }
    },
    computed: {
        modalState: {
            get: function(){
                return this.diagState
            },
            set: function(newValue){
                this.$emit('update:diagState', newValue)
            }   
        },
    },
    mounted:function (){
        this.getVersion()
    },
    methods: {
        getVersion(){
            this.$http.get( '/versions/?per_page=10&order_by=ver_id&order_direction=desc' ).then( (response) => {
                response.data.data.forEach(element => {
                    let versionTab = element.ver_version.split('.')
                    if( versionTab[2] !== undefined && parseInt(versionTab[2]) > 0 ){
                        element.ver_icon  = 'bug-check-outline'
                        element.ver_color = 'red'
                    } else {
                        element.ver_icon = 'check'
                        element.ver_color = 'green'
                    }
                })
                this.items = response.data.data
            })
        }
    },
    components: { 

    }
}
</script>