<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn color="success" depressed class="white--text" :to="'/' + $route.name + '/0'">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn> -->
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" v-bind:items="items" v-bind:search="search"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:[`item.doc_sta_id`]="{ item }">
            <v-chip small outlined label :color="getCacheState('GEN', item.doc_sta_id).sta_color">{{ getCacheState('GEN', item.doc_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.doc_typ_id`]="{ item }" :color="( item.doc_sta_id == 'GEN01' ) ? 'green' : 'grey'">
            <v-chip small outlined label :color="getCacheType('DOC', item.doc_typ_id).typ_color">{{ getCacheType('DOC', item.doc_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.doc_id`]="{ item }" :color="'grey'">
            <v-chip label outlined :title="item.equ_id" @click="downloadDocument(item.doc_id)">
                <v-icon left color='grey'>mdi-file-download</v-icon>
            </v-chip>
            <!-- <v-btn v-if="item.doc_xxx_id && item.doc_table_id == 'trs'" :href="'#/documents/sheet/' + item.doc_xxx_id" @click.stop small rounded outlined color="primary" dark class="ml-2">
                <v-icon>mdi-application-export</v-icon>
            </v-btn> -->
        </template>
        <template v-slot:[`item.doc_created_at`]="{ item }" :color="'grey'">
            <v-chip label outlined >{{ locDateFormat.formatLocale( item.doc_created_at, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:[`item.doc_xxx_id`]="{ item }">
            <v-btn :href="'#/documents/sheet/' + item.act_equ_id + '/action/' + item.doc_xxx_id" 
                small rounded outlined color="primary" dark>
                <v-icon>mdi-folder-open-outline</v-icon>&nbsp;{{ originName[item.doc_table_id].name }}
            </v-btn>
        </template>
        <template slot="no-data">
            <v-alert :value="true" color="error" class="mt-2" icon="mdi-alert">Aucun document</v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { docObj }       from '../mixins/doc_obj.js'
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'document',
    props: ['toast', 'filterView', 'contextStyle'],
    mixins: [ docObj ],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            originName: { 
                'equ' : {name: 'Matériel', link: ''},
                'eve' : {name: 'Evenement', link: ''},
                'act' : {name: 'Tache', link: ''},
            },
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0 },
            headers: [
                { text: 'Statut',  align: 'left', value: 'doc_sta_id'},
                { text: 'Type',    align: 'left', value: 'doc_typ_id'},
                { text: 'Nom',     align: 'left', value: 'doc_name'},
                { text: 'Creation', value: 'doc_created_at' },
                { text: 'Action',  align: 'left', value: 'doc_id'},
                { text: '-',    align: 'left', value: 'doc_xxx_id'},
            ],
            items: [],
            locDateFormat: dateLocale, // Format de date
            cacheType: cacheGetters.getTypes(),
            cacheState: cacheGetters.getStates(),
            enableAdd: true,
            modalEvent : false,
            genFilter: true
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    watch: {
        //filterView: function(){
        //    if( !this.filterView ){
        //        this.getDocument()
        //    }
        //},
        //genFilter: function(){
        //    this.filterDocument.withequipment = this.genFilter
        //    this.getDocument()
        //}
    },
    created(){
        this.subFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getDocument()
            }
        })
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('documents')
        this.getDocument()
    },
    methods: {
        getDocument(){
            this.items = []
            this.pagination.page = 1
            this.getDocumentIndex('extended=withaction').then( () => {
                if( this.metaDocument.pagination.total !== undefined && this.metaDocument.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(this.metaDocument.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                this.items = this.listDocument
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        openSite(equ_id){
            this.$router.push({ path: '/equipments/sheet/' + equ_id })
        },
        downloadSite(){
            this.toast({ color: 'success', text: 'Fonction non disponible !' })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getDocument()
        },
        downloadDocument(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.toast({ color: 'error', text: error })
            })
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('documents', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    beforeDestroy() {
        this.subFilter.unsubscribe()
    },
    components: {
        
    }
}
</script>

<style lang="css">

</style>