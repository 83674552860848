<template>
<v-card>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-menu ref="datestart" v-model="dateMenu.date_start"
                :return-value.sync="objTime.wti_date"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objTime.wti_date, 'fr' )"
                        label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTime.wti_date">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_start = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datestart.save( objTime.wti_date )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>

        <v-flex xs6 class="pa-2">
            <!-- <v-select prepend-icon="mdi-account" :items="[ { name:user.usr_middleName, id:user.usr_id } ]" item-text="name" item-value="id" v-model="objTime.wti_usr_id" label="Agent" single-line></v-select> -->
            <auto-multi v-if="!assocItem" :title="'Agent'" ressource="users" :listObject.sync="autoComplete.user"
                :listParent.sync="autoCompleteSource.user" :fields="{ 
                    index: 'usr_id', text:'usr_firstName,usr_lastName', search:'usr_middleName', multiSelect:false 
                }"
                :disabled="!isAuthorized( 'Worktime', 'validate')"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select prepend-icon="mdi-timetable" :items="types" item-text="typ_name" item-value="typ_id" 
                v-model="objTime.wti_typ_id" label="Statut" single-line >   
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field prepend-icon="mdi-clock-time-two-outline" label="Temps de travail" 
                v-model="objTime.wti_duration" type="numeric" :disabled="!(objTime.action.data.act_sta_id == 'GEN01' || isAuthorized( 'Worktime', 'validate' ))" >
            </v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-chip label outlined v-show="objTime.wti_created_at" class="d-flex flex-column">
                Création : 
                {{ locDateFormat.toLocale( objTime.wti_created_at, { year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' } ) }}
            </v-chip>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-menu v-if="isAuthorized( 'Worktime', 'destroy' ) && (!objTime.wti_validated) && (objTime.wti_id > 0)" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteWorktime()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn rounded v-if="(objTime.action.data.act_sta_id == 'GEN01' || isAuthorized( 'Worktime', 'validate' )) && (!objTime.wti_validated || isAuthorized( 'Worktime', 'updatevalidated' ))"
            color="success" 
            :loading="saveProgress" @click.native="saveWorktime()" :disabled="!validForm">
            Enregistrer
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('modalWorktime', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
//import { wtiObj } from '../mixins/eve_obj.js'
import { cacheData, cacheGetters } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'editworktime',
    props: ['toast', 'switchModal', 'wti_id', 'act_id'],
    //mixins: [ wtiObj ],
    data: function () {
        return {
            loading : { getSheet: false },
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            types : [],
            dateEve: { start: false, end: false },
            optionMenu: false,
            objTime: {
                wti_id       : 0, 
                wti_act_id   : 0, 
                wti_usr_id   : 0, 
                wti_typ_id   : 'WTI01', 
                wti_date     : '', 
                wti_duration : '',
                wti_validated: 0,
                wti_created_at: null,
                action : { data: { act_sta_id: 'GEN01' } }
            },
            autoComplete: {
                user      : [],
            },
            autoCompleteSource:{
                user     : [],
            },
            assocItem: true,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {
        user(){
            return cacheData.user
        },
        validForm(){
            if(this.objTime.wti_duration > 0){
                return true
            } else {
                return false
            }
        },
    },
    //beforeMount: function(){ },
    mounted:function (){
        this.objTime.wti_date = this.locDateFormat.getToday('en')
        this.objTime.wti_usr_id = this.user.usr_id
        this.objTime.wti_act_id = this.act_id
        this.types = cacheGetters.getTypes('WTI')
        if( this.wti_id && this.wti_id > 0){
            this.objTime.wti_id     = this.wti_id
            this.getItmem()
        } else {
            this.autoComplete.user = this.user.usr_id
            this.autoCompleteSource.user.push( this.user ) //{ usr_middleName: this.user.usr_middleName, usr_id: this.user.usr_id }
            this.assocItem = false
        }
    },
    methods: {
        async getItmem(){
            await this.getWorktime()
        },
        getWorktime(){
            return new Promise( (resolve, reject) => {
                this.loading.getSheet = true
                this.$http.get( '/worktimes/' + this.objTime.wti_id + '/?include=user,action').then( (response) => {
                    this.objTime = response.data.data
                    this.loading.getSheet = false
                    this.autoComplete.user = this.objTime.wti_usr_id
                    this.autoCompleteSource.user.push(this.objTime.user.data)
                    this.assocItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveWorktime(){
            this.objTime.wti_usr_id = this.autoComplete.user
            if( this.objTime.wti_id > 0 ){
                this.$http.put( '/worktimes/' + this.objTime.wti_id, this.objTime ).then( () => {
                    this.loading.getSheet = false
                    this.switchModal('modalWorktime', 0, 1)
                })
            } else {
                this.$http.post( '/worktimes/', this.objTime ).then( () => {
                    this.loading.getSheet = false
                    this.switchModal('modalWorktime', 0, 1)
                })
            }
        },
        deleteWorktime(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/worktimes/' + this.objTime.wti_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.switchModal('modalWorktime', 0, 1)
                    })
                }
            })
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>