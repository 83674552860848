<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="8">
        <v-card :class="uiParams.cardClass" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> Rechercher du materiel</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="onScan" color="orange" @click="cancelScan()">
                    <v-icon left>mdi-text-search</v-icon>Annuler
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-btn v-show="!onScan" x-large color="primary" block @click="scannfc()">
                    <v-icon left>mdi-text-search</v-icon>Scanner
                </v-btn>
                <v-row v-show="onScan" class="fill-height" align-content="center" justify="center">
                    <v-col class="subtitle-1 text-center" cols="12">Approcher le scanner de la puce</v-col>
                    <v-col cols="6"><v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear></v-col>
                </v-row>
                <v-row v-show="!onScan" class="fill-height" align-content="center" justify="center">
                    <v-col cols="4" class="subtitle-1 text-center" >
                        <v-select 
                                :prepend-icon="searchTypeSelected.icon"
                                :items="searchTypeList" item-value="value" v-model="searchType" label="Recherche par" single-line>
                            <template slot="item" slot-scope="data">
                                <v-list>
                                <v-list-item>
                                    <v-list-item-action><v-icon>{{data.item.icon}}</v-icon></v-list-item-action>
                                    <v-list-item-content><v-list-item-title>{{data.item.text}}</v-list-item-title></v-list-item-content>
                                </v-list-item>
                                </v-list>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field label="Rechercher" placeholder="" v-model="searchText"></v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-progress-circular v-if="loading.getItem" indeterminate color="primary"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="4">
        <v-card :class="uiParams.cardClass" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> Résultats</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list class="pb-0" two-line>
                <template v-for="(item, index) in equipments">
                    <v-list-item :key="index" class="pb-0" :href="'/#/dashboard/sheet/' + item.equ_id ">
                        <!-- <v-list-item-avatar><v-chip small color="green">{{ item.equ_code }}</v-chip></v-list-item-avatar> -->
                        <v-list-item-content>
                            <v-list-item-title :style="'color:' + item.sta_color + ';'">
                                {{ item.cat_name }} <span class="caption grey--text">({{ item.equ_code  }})</span>
                            </v-list-item-title>
                            <v-list-item-subtitle :class="(item.equ_nfcid) ? 'grey--text caption font-italic' : 'red--text caption font-italic'">
                                Serie: {{ item.equ_serial }}
                                <br v-if="item.equ_registration" /><span v-if="item.equ_registration">Immat: {{item.equ_registration}}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <span class="caption">{{ item.bmo_code }}</span>
                            <v-chip small outlined label light :color="(cacheType['ENY'][item.bmo_typ_id_energy]) ? cacheType['ENY'][item.bmo_typ_id_energy].typ_color : 'grey'">
                                {{ (cacheType['ENY'][item.bmo_typ_id_energy]) ? cacheType['ENY'][item.bmo_typ_id_energy].typ_name : '-' }}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="'D-' + index"></v-divider>
                </template>
            </v-list>
        </v-card>
    </v-col>
</v-row>

</v-container>
</template>

<script>
import dateLocale       from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { equObj }       from '../mixins/equ_obj.js'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'dashboard',
    props: ['toast', 'filterView'],
    mixins: [ equObj ],
    data () {
        return {
            uiParams: {
                cardClass: 'elevation-0  rounded-lg pb-1',
                cardToolbarColor: '#FAFAFA'
            },
            loading: { getItem: false, getRessource: false },
            onScan: false,
            isAuthorized: usrGetters.getScopeRight,
            saveProgress: false,
            locDateFormat: dateLocale, // Format de date
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            equipments: [],
            events: [],
            stateState: cacheGetters.getStates(),
            cacheType: cacheGetters.getTypes(),
            searchText: '',
            searchType: 'equ_serial',
            searchTypeList: [
                {value: 'equ_serial',  text: 'N° série',  icon: 'mdi-barcode-scan' },
                {value: 'equ_code',    text: 'Code',      icon: 'mdi-counter' },
                {value: 'cat_name',    text: 'Catégorie', icon: 'mdi-format-list-numbered' }, // par nom et par ID
                {value: 'bra_name',    text: 'Marque',    icon: 'mdi-watermark' }, // Par marque
                {value: 'bmo_code',    text: 'Modèle',    icon: 'mdi-animation-outline' }, // Par modèle
                {value: 'equ_nfcid',   text: 'NFC',       icon: 'mdi-nfc-variant' },
                {value: 'equ_registration',  text: 'Immatriculation',  icon: 'mdi-car-search' },
            ],
            fields: { search: 'equ_serial' },
            params: '&extended=withentity,withcategory,withbrand,withbrandmodel,withgroup',
            ndef     : null,
            nfcAbort : new AbortController(),
        }
    },
    computed: {
        searchTypeSelected() {
            return this.searchTypeList.find(x => x.value === this.searchType)
        }
    },
    watch: {
        searchText (val) {
            if( this.loading.getRessource ){
                clearTimeout(this.loading.getRessource)
            }
            if( val && val.length > 1 ){
                var searchTab = this.searchType.split(',') //this.fields.search.split(',')
                var search = ''
                searchTab.forEach( (elem) => {
                    search += '&' + elem.trim() + '=%' + val + '%'
                })
                search = '&' + searchTab[0].trim() + '=%' + val + '%' //Todoo multi -> delete this line
                this.loading.getRessource = setTimeout( () => {
                    this.loading.getItem = true
                    this.equipments = []
                    this.$http.get( '/equipments/?per_page=9' + this.params + encodeURI(search) ).then( (response) => {
                        this.equipments = response.data.data
                        this.loading.getItem = false
                    })
                }, 800)
            } else {
                return false
            }
        },
        filterView: function(){
            if( !this.filterView ){
                return true
            }
        }
    },
    mounted:function (){
        if ('NDEFReader' in window) {
            this.scanLabel = 'cliquez pour scanner'
            /*global NDEFReader*/
            this.ndef      = new NDEFReader()
            this.nfcAbort.signal.onabort = event => { 
                this.ndef.removeEventListener("reading")
                console.log(event) 
            }
            this.ndef.onreading = e => {
                if( this.onScan ){
                    this.searchText   = e.serialNumber
                    console.log(e)
                    setTimeout( () => {
                        this.onScan       = false
                        //this.cancelScan()
                    }, 1500)
                }
            }
        } else {
            this.scanLabel = 'Votre appareil ne peut pas lire du NFC'
        }
    },
    methods:{
        async scannfc(){
            this.onScan     = true
            this.searchType = 'equ_nfcid'
            this.scanLabel  = 'Scannez la puce'
            await this.ndef.scan({ signal: this.nfcAbort.signal })

        },
        cancelScan(){
            this.searchType = 'equ_serial'
            this.searchText = ''
            this.onScan = !this.onScan
            this.nfcAbort.abort()
            //this.ndef.removeEventListener("reading")
        },
        goRoute(route){
            this.$router.push({ path: '/' + route + '/' })
        },
        openEvent(id, table_id){
            this.eveInfo.eve_id     = id
            this.eveInfo.eve_xxx_id = table_id
            this.switchModal('modalEvent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        totalColor( total ){
            if( total <= 50 ){
                return 'red'
            } else if( total <= 80 ){
                return 'warning'
            } else {
                return 'green'
            }
        },
        getRandomInt () {
            return Math.floor(Math.random() * (20 - 5 + 1)) + 5
        },
        testEndpoint(){
            //this.$http.get( '/actiontasks?per_page=false' ).then( (response) => { this.items = response.data.data })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        //'apexchart': VueApexCharts //LineChart
    }
}
</script>

<style>
  .small {
    max-width: 600px;
  }
</style>