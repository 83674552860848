<template>
<v-container fluid class="pa-1">
    <v-data-table :headers="headers" :items="items" :items-per-page="10" class="elevation-1" >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title v-show="(title)">
                    <v-alert class="mb-0" border="left" dense colored-border type="info">{{title}}</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn v-if="isAuthorized( 'Event', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openEvent(0)"> <!-- :disabled="!routeState.edit" -->
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>        
        <template v-slot:[`item.eve_typ_id`]="{ item }">
            <v-chip label outlined :color="typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_color">{{ typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.eve_sta_id`]="{ item }">
            <v-chip label outlined color="green">{{ stateState['GEN'][item.eve_sta_id].sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.eve_date_start`]="{ item }">
            <v-chip label outlined>{{ locDateFormat.toLocale( item.eve_date_start ) }}</v-chip>
        </template>
        <template v-slot:[`item.eve_id`]="{ item }">
            <v-icon left color="orange" v-if="isAuthorized( 'Event', 'update' )" @click="openEvent(item.eve_id)">mdi-pencil</v-icon>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !loading.getEvent )" color="error" icon="mdi-alert">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Evenement</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-event class="elevation-2" 
                v-if="modalEvent" :toast="toast" :switchModal="switchModal"
                :xxxId="eve_xxx_id" :eveId="eveInfo.eve_id" :eveTable="table_id" :eveType="typfilter">
            </edit-event>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import editEvent  from '../components/Edit_event.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"

export default {
    name: 'tab_event',
    props: ['toast', 'table_id', 'eve_xxx_id', 'title', 'typfilter', 'routeState'],
    data: function () {
        return {
            loading: { uploadBtn: false, rebuildBtn: false, getEvent: false },         // Enregistrement en cours...
            saveProgress: false,         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            headers: [
            { text: 'Description', align: 'left', value: 'eve_code'},
            { text: 'Date', align: 'left', value: 'eve_date_start'},
            { text: 'Type', align: 'left', value: 'eve_typ_id'},
            { text: 'Statut', value: 'eve_sta_id' },
            { text: 'Action', value: 'eve_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            prv_id: 0,
            eve_id: 0,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: this.table_id, eve_xxx_id  : this.eve_xxx_id },
            modalPagin : true,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    mounted: function (){
        this.getEvent()
    },
    watch: {
        //filter_table: function(){
        //    this.getEvent()
        //},
        //filter_eve: function(){
        //    this.getEvent()
        //}
    },
    methods: {
        getEvent(){
            this.items = []
            this.loading.getDocument = true
            var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            searchCriteria    += '&eve_xxx_id=' +  this.eve_xxx_id + '&eve_table_id=' + this.table_id
            searchCriteria    += ( this.typfilter ) ? '&eve_typ_id=%' + this.typfilter + '%' : ''
            this.$http.get( '/events/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getEvent = false
            })
        },
        openEvent(id){
            this.eveInfo.eve_id = id
            this.switchModal('modalEvent', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalEvent', 1)
            }
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
            if( refresh ){ 
                this.getEvent()
            }
        }
    },
    components: {
        'edit-event'   : editEvent
    }
}
</script>