/* *****************************
    Objet : Action
******************************** */
import { cacheGetters } from "../store/cache.module"
import dateLocale from '../services/dateLocale'

export const actObj = {
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            objAction: { 
                act_id : 0, act_sta_id : 'GEN01', act_typ_id : 'ACT01', act_typ_id_kind : 'ACK02', 
                act_equ_id : '', act_tla_id : null, act_ent_id : null,
                act_usage_hour : '', act_usage_distance : '', act_description : '', act_observation : '', 
                act_price : '', act_item_price : '', 
                act_date_dueon : null, act_date_start : null, act_date_end : null,
                act_usr_id_owner : '', act_usr_id_created : null, act_created_at : '',
                _info: { entity: {} }
            },
            objActionClone: {},
            listAction: [],
            metaAction: {},
        }
    },
    mounted: function (){
        this.objActionClone           = JSON.stringify( this.objAction )
        this.objAction.act_date_start = this.locDateFormat.getToday('en')
    },
    methods: {
        getActionFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            let fields = { 
                bra_id: 'bra_id', bmo_id: 'bmo_id', equ_code:'equ_code',
                equ_serial:'equ_serial', sta_id: 'act_sta_id', ent_id: 'act_ent_id', min_date_plan: 'min_act_date_start', max_date_plan: 'max_act_date_start' }
            var searchCriteria = '?page='+ page +'&per_page=' + perPage
            for( var prop in filter ){
                if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 && fields[prop] ){
                    searchCriteria += '&' + fields[prop] + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        // Get Action
        getActionShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/?include=' + options
                }
                if( !this.objAction.act_id > 0 ){
                    resolve('resolved')
                } else {
                    this.$http.get( '/actions/' + this.objAction.act_id + options).then( (response) => {
                        var prop
                        for( prop in response.data.data ){
                            if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                                this.objAction._info[prop] = response.data.data[prop]['data']
                            } else {
                                this.objAction[prop] = response.data.data[prop]
                            }
                        }
                        this.objAction.act_date_end   = ( this.objAction.act_date_end.substring(0, 4)   == '0000' )   ? null : this.objAction.act_date_end
                        this.objAction.act_date_start = ( this.objAction.act_date_start.substring(0, 4) == '0000' ) ? this.locDateFormat.getToday('en') : this.objAction.act_date_start
                        this.objAction.act_date_dueon = ( this.objAction.act_date_dueon.substring(0, 4) == '0000' )   ? null : this.objAction.act_date_dueon
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                }
            })
        },
        getActionIndex(options, page, perPage){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listAction = []
                let searchCriteria = this.getActionFilter(page, perPage)
                if(options.length > 0){
                    options = '&' + options
                }
                this.$http.get( '/actions/' + searchCriteria + options ).then( (response) => {
                    this.metaAction = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listAction = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putActionUpdate(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/actions/' + this.objAction.act_id, this.objAction ).then( (response) => {
                    this.objAction.act_id = response.data.data.act_id
                    this.updateEquipment()
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postActionStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/actions', this.objAction ).then( (response) => {
                    this.objAction.act_id = response.data.data.act_id
                    this.updateEquipment()
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteObjAction(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/actions/' + this.objAction.act_id, this.objAction ).then( () => {
                    this.objAction.act_id = 0
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cleanObjAction(){
            var ActionClone = JSON.parse( this.objActionClone )
            var prop
            for( prop in ActionClone ){
                if( this.objAction[prop] !== undefined ){
                    this.objAction[prop] = ActionClone[prop]
                }
            }
        },
        async updateEquipment(){
            this.$http.put( '/equipments/' + this.objAction.act_equ_id + '/updatefromaction/', { act_typ_id: this.objAction.act_typ_id} )
        }
    }
}