<template>
<v-sheet color="#eeeeee" class="elevation-0 pa-0 ma-3">
    <!-- Block info with option -->
    <template v-for="(itemBlock, indexBlock) in blockObj">
        <v-subheader :key="'h-' + indexBlock" v-if="itemBlock.left.length > 0 || itemBlock.right.length > 0">
            <v-flex xs5>{{ itemBlock.left }}</v-flex>
            <v-flex xs7>{{ itemBlock.right }}</v-flex>
        </v-subheader>
        <v-layout wrap style="background-color:white;" class="mt-3 pa-2 elevation-1" :key="indexBlock">
            <!-- Block Left -->
            <v-flex xs5 style="border-right:1px solid #E0E0E0;">
                <v-list dense>
                    <template v-for="(itemLeft, indexLeft) in blockObj[indexBlock].data['left']">
                    <v-list-item :key="indexLeft" class="min-item-height">
                        <v-list-item-content><v-list-item-title>{{ itemLeft.text}}</v-list-item-title></v-list-item-content>
                        <v-list-item-action v-if="itemLeft.mod == 'chip'">
                            <v-chip v-if="itemLeft.value !== 0" color="primary" small label outlined>{{ itemLeft.value }} {{ (itemLeft.unit) || '' }}</v-chip>
                            <v-icon v-else light :title="itemLeft.title">mdi-information-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action v-else><v-list-item-action-text>{{ itemLeft.value }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            <!-- Block Right -->
            <v-flex xs4 style="border-right:1px solid #E0E0E0;">
                <v-list dense class="min-item-height">
                    <template v-for="(itemRight, indexRight) in blockObj[indexBlock].data['right']">
                    <v-list-item :key="indexRight" class="min-item-height">
                        <v-list-item-content><v-list-item-title>{{ itemRight.text }}</v-list-item-title></v-list-item-content>
                        <v-list-item-action v-if="itemRight.mod == 'chip'">
                            <v-chip v-if="itemRight.value" color="primary" small label outlined :title="itemInfo.text">{{ itemRight.value }}</v-chip>
                            <v-icon v-else light :title="itemRight.title">mdi-information-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action v-else><v-list-item-action-text>{{ itemRight.value }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            <!-- Block Info -->
            <v-flex xs3>
                <v-list dense class="min-item-height">
                    <template v-for="(itemInfo, indexInfo) in blockObj[indexBlock].data['info']">
                    <v-list-item :key="indexInfo">
                        <!-- <v-list-item-content><v-list-item-title>{{ itemInfo.text }}</v-list-item-title></v-list-item-content> -->
                        <v-list-item-action v-if="itemInfo.mod == 'chip'" class="mr-0">
                            <v-chip v-if="itemInfo.value" color="primary" small label outlined :title="itemInfo.text">
                                <v-icon v-if="itemInfo.icon" left>{{itemInfo.icon}}</v-icon>
                                {{ itemInfo.value }}
                            </v-chip>
                            <v-icon v-else light :title="itemInfo.title">mdi-information-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action v-else><v-list-item-action-text>{{ itemInfo.value }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            <v-flex xs12 >
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-btn outlined block @click="updateNfc()"
                                :color="(objEquipment.equ_nfcid) ? 'green' : 'orange'" :loading="loading.updateNfc" small>
                                <v-icon>mdi-cellphone-nfc</v-icon>
                                <span v-if="objEquipment.equ_nfcid">{{objEquipment.equ_nfcid}}</span>
                                <span v-else>N.F.C.</span>
                            </v-btn>                            
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-flex>
        </v-layout>
    </template>
    <v-card tile flat class="mt-3 elevation-1">
    <v-toolbar flat color="#f6f8f8" prominent height="50px">
        <v-toolbar-title>
            <v-icon>mdi-format-list-checks</v-icon>
            Derniers travaux
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mt-1" title small :color="(activeState == 'GEN01') ? 'green' : 'grey lighten-2'" @click="activeState='GEN01'; getAction()">Actif</v-btn>
        <v-btn class="mt-1 ml-1" title small :color="(activeState  == 'GEN03') ? 'green' : 'grey lighten-2'" @click="activeState='GEN03'; getAction()">Cloturé</v-btn>
        <!-- <v-btn icon><v-icon>mdi-restore</v-icon></v-btn> -->
    </v-toolbar>
    <v-divider></v-divider>
    <v-list class="pb-0" two-line>
        <template v-for="(item, index) in listAction">
            <v-list-item :key="index" class="pb-0" :href="link.base + '/sheet/' + objEquipment.equ_id + '/action/' + item.act_id ">
                <!-- <v-list-item-avatar class="mt-0 mb-0"><v-chip small color="green">{{ item.act_id }}</v-chip></v-list-item-avatar> -->
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.act_description }} <!-- <span class="caption grey--text">({{ item.act_description }})</span> -->
                        <v-chip class="ma-0 ml-2" :color="getCacheType('ACK', item.act_typ_id_kind).typ_color" x-small label text-color="white">
                            <v-icon left>mdi-label</v-icon>{{ getCacheType('ACK', item.act_typ_id_kind).typ_name }}
                        </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption font-italic">
                        Compteur: {{ ( (item.act_usage_distance !== 0) ? item.act_usage_distance : item.act_usage_hour ) }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <span v-if="activeState=='GEN01'" class="text--secondary caption" >Prévu le {{ locDateFormat.toLocale(item.act_date_start, { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                    <span v-if="activeState=='GEN03'" class="text--secondary caption" >Cloturé le {{ locDateFormat.toLocale(item.act_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                    <v-chip small outlined label light :color="getCacheType('ACT', item.act_typ_id).typ_color">
                        {{ getCacheType('ACT', item.act_typ_id).typ_name }}
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
            <v-divider :key="'D-' + index"></v-divider>
        </template>
    </v-list>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8"></v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" @input="getAction" class="my-2" :length="pagination.totalItems"></v-pagination>
        </v-flex>
    </v-layout>
    <v-fab-transition v-if="isAuthorized( 'Action', 'store' )">
      <v-btn color="green" rounded large dark bottom absolute right :href="link.base + '/sheet/' + objEquipment.equ_id + '/action/0'">
        <v-icon>mdi-plus</v-icon>Ajouter une fiche
      </v-btn>
    </v-fab-transition>
    <!-- <v-subheader >Autre</v-subheader>
    <v-layout wrap style="background-color:white;" class="mt-0 pa-2 elevation-1">
        <v-btn grey rounded color="secondary" @click="goToDocument()">Voir les documents associés</v-btn>
    </v-layout>
    <v-subheader v-if="isAuthorized( 'Equipment', 'destroy' )">Administration</v-subheader>
    <v-layout    v-if="isAuthorized( 'Equipment', 'destroy' )" wrap style="background-color:white;" class="mt-0 pa-2 elevation-1">
        <v-flex xs6 class="pa-2">
            <v-btn block small outlined light :color="'orange'" @click="closeSheet()">Archiver la fiche</v-btn>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-btn block small outlined light :color="'red'" @click="deleteSheet()">Supprimer la fiche</v-btn>
        </v-flex>
    </v-layout> -->

</v-sheet>
</template>

<script>
import { cacheGetters, cacheMutations } from "../store/cache.module"
import dateLocale       from '../services/dateLocale';
import { usrGetters }   from "../store/user.module"
import { actObj }       from '../mixins/act_obj.js'

export default {
    name: 'equ_slide_prev',
    props: ['toast', 'link', 'routeState', 'objEquipment'], 
    mixins: [ actObj ],
    data: function () {
        return {
            pagination: { page:1, totalItems: 0, rowsPerPage: 5, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            loading: { getSite: false, updateNfc: false },
            locDateFormat: dateLocale, // Format de date
            typeState: cacheGetters.getTypes(),
            activeState: 'GEN01',
            //activeTab: 0, showTab: true,
            sit_obj: {},
            blockObj: {
                info : { data: { 'left': [], 'right': [], 'info': [] }, 'left': '', 'right': '', 'info': '' },
                //proc : { data: { 'left': [], 'right': [] }, 'left': 'Fiche', 'right': 'Info materiel' },
            },
            mesureLabel: [ '', 'En demande', 'Validé', 'non validé' ],
            colorLabel: [ '#607D8B', '#607D8B', '#4CAF50', '#F44336' ],
            siteRecap: [],
            //actionItems: [],
            isAuthorized: usrGetters.getScopeRight,
            item: {},
            ndef: null,
            scanLabel: '',
            nfcAbort : new AbortController()
        }
    },
    computed: {

    },
    beforeMount: function(){
        this.link.base = '/#' + this.$route.matched[1].path
    },
    mounted:function (){
        this.updateInfoData()
        this.getAction()
    },
    beforeDestroy: function(){
        try{
            if( this.ndef !== null && this.ndef ){
                this.ndef.removeEventListener("reading")
            }
        } catch (error) {
            this.scanLabel = "Erreur ! " + error
        }
    },
    methods: {
        async getAction(page){
            page = page || 1
            this.pagination.page = page
            await this.getActionIndex('order_by=act_date_start&order_direction=DESC&act_sta_id=' + this.activeState + '&act_equ_id=' + this.objEquipment.equ_id, this.pagination.page, this.pagination.rowsPerPage)
            this.pagination.totalItems = this.metaAction.pagination.total_pages
        },
        updateInfoData(){
            //this.blockObj.info.data['left'].push(  { text: 'Agence', value: '' })
            let usage = (this.objEquipment.equ_usage_distance !== 0) ? this.objEquipment.equ_usage_distance : this.objEquipment.equ_usage_hour

            this.blockObj.info.data['left'].push(  { text: 'Matricule',   value: this.objEquipment.equ_code, switch: false, text2: 'N°Serie', value2: this.objEquipment.equ_serial } )
            this.blockObj.info.data['left'].push(  { text: 'Groupe',      value: this.objEquipment._info.group.grp_name })
            this.blockObj.info.data['left'].push(  { text: 'Catégorie',   value: this.objEquipment._info.category.cat_name })
            this.blockObj.info.data['left'].push(  { text: 'Aquisition',  value: this.getCacheType('TRA', this.objEquipment.equ_typ_id_trade).typ_name })
            this.blockObj.info.data['left'].push(  { text: 'Propriétaire',value: this.objEquipment._info.entity.ent_name })
            this.blockObj.info.data['left'].push(  { text: 'Dépôt',       value: this.objEquipment._info.store.str_name })

            //this.blockObj.info.data['right'].push( { text: 'Dépôt',      value: '' })
            this.blockObj.info.data['right'].push( { text: 'Immat.',     value: this.objEquipment.equ_customfields.equ_registration })            
            this.blockObj.info.data['right'].push( { text: 'Marque',     value: this.objEquipment._info.brand.bra_name })
            this.blockObj.info.data['right'].push( { text: 'Modèle',     value: this.objEquipment._info.brandmodel.bmo_code } )
            this.blockObj.info.data['right'].push( { text: 'Index',      value: usage })
            this.blockObj.info.data['right'].push( { text: 'Code',       value: this.objEquipment.equ_code })
            this.blockObj.info.data['right'].push( { text: 'Agence',     value: this.objEquipment._info.agency.agc_name })

            this.blockObj.info.data['info'].push(  { 
                text: 'Creation', value: this.locDateFormat.toLocale(this.objEquipment.equ_created_at, { year: 'numeric', month: 'numeric', day: 'numeric' }), 
                mod:'chip', icon: 'mdi-account-circle-outline'
            })
            this.blockObj.info.data['info'].push( {
                text: 'Energie',value: this.getCacheType('ENY', this.objEquipment._info.brandmodel.bmo_typ_id_energy).typ_name, 
                mod:'chip', icon: 'mdi-lightning-bolt'
            })
            this.blockObj.info.data['info'].push( {
                text: 'Réparation', value: this.locDateFormat.toLocale(this.objEquipment.equ_repair_at, { year: 'numeric', month: 'numeric', day: 'numeric' }), 
                mod:'chip', icon: 'mdi-car-wrench'
            })
            this.blockObj.info.data['info'].push( {
                text: 'N°Série', value: this.objEquipment.equ_serial, 
                mod:'chip', icon: 'mdi-barcode'
            })
        },
        updateActions(){
            this.$http.get( 'actions/?per_page=false&extended=withcoderef&act_equ_id=' +  this.objEquipment.equ_id).then( (response) => {
                response.data.data.forEach( (item) => {
                    this.blockObj.proc.data['left'].push( { text: item.act_id, value: item.spf_area, title:item.title, unit:'h', mod:'chip' })
                })
            })
        },
        goToDocument(){
            cacheMutations.setSearchBarItem('documents', this.objEquipment.equ_id)
            this.$router.push({ path: '/documents' })
        },
        closeSheet(){
            this.$root.$confirm('Cloturer', 'Confirmer la fermeture de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.objEquipment.equ_sta_id = "GEN04"
                    this.putEquipmentStore().then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche clôturée !' })
                        this.$router.push({ path: '/equipments' })
                    })
                }
            })
        },
        deleteSheet(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.objEquipment.equ_sta_id = "GEN05"
                    this.putequipmentStore().then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/equipments' })
                    })
                }
            })
        },
        async updateNfc(){
            this.nfcAbort.signal.onabort = event => { console.log(event) }
            this.loading.updateNfc = true
            /*global NDEFReader*/
            try {
                this.scanLabel = 'Scannez la puce'
                this.ndef = new NDEFReader()
                await this.ndef.scan({ signal: this.nfcAbort.signal })
                this.ndef.addEventListener("readingerror", () => {
                    this.scanLabel = "Impossible de lire la puce"
                })
                this.ndef.addEventListener("reading", ({ message, serialNumber }) => {
                    //this.searchText   = serialNumber
                    this.objEquipment.equ_nfcid = serialNumber
                    this.loading.updateNfc = false
                    console.log(message)
                    this.$http.put( '/equipments/' + this.objEquipment.equ_id, { equ_sta_id: 'GEN01', equ_nfcid: this.objEquipment.equ_nfcid } ).then( () => {
                        this.loading.updateNfc = false
                        this.toast({ color: 'success', text:'Enregistré !' })
                        this.nfcAbort.abort()
                        this.ndef.removeEventListener("reading")
                    })
                });
            } catch (error) {
                this.scanLabel = "Erreur ! " + error
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {

    }
}
</script>

<style lang="css">
/* Personnalisation code */
.min-item-height {
  min-height: 10px !important;
}

</style>