<template>
<v-container fluid class="pa-1">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Liste des temps</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="(objAction.act_sta_id !== 'GEN03' || isAuthorized( 'Worktime', 'validate' ))"
            outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openWorktime(0)">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getWorktime"> 
        <template v-slot:[`item.usr_firstName`]="{ item }" :color="'grey'">
            <span class="d-inline-block text-truncate font-weight-bold" style="max-width: 400px;" :title="item.usr_lastName + ' ' + item.usr_firstName">
            {{ item.usr_lastName.substring(0,1) + '. ' + item.usr_firstName }}
            </span>
        </template>
        <template v-slot:[`item.wti_date`]="{ item }" :color="'grey'">
            <v-chip label outlined >{{ locDateFormat.formatLocale( item.wti_date, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:[`item.wti_duration`]="{ item }" :color="'grey'">
            <v-chip outlined >{{ item.wti_duration }}</v-chip>&nbsp;
            <v-icon v-if="(item.wti_typ_id == 'WTI01')" :color="getCacheType('WTI', item.wti_typ_id).typ_color" :title="getCacheType('WTI', item.wti_typ_id).typ_name">
                mdi-screw-machine-flat-top
            </v-icon>
            <v-icon v-else :color="getCacheType('WTI', item.wti_typ_id).typ_color" :title="getCacheType('WTI', item.wti_typ_id).typ_name">
                mdi-home-city-outline
            </v-icon>
        </template>
        <template v-slot:[`item.wti_validated`]="{ item }" :color="'grey'">
            <v-icon v-if="(item.wti_validated)" color="green">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.wti_id`]="{ item }" :color="'grey'">
            <v-icon 
                v-if="(objAction.act_sta_id == 'GEN01') && !item.wti_validated && isAuthorized( 'Worktime', 'update' )" 
                left color='orange' title="Editer" @click="openWorktime(item.wti_id)">
                mdi-pencil
            </v-icon>
            <v-icon v-else :color="getCacheType('WTI', item.wti_typ_id).typ_color" title="Voir" @click="openWorktime(item.wti_id)">
                mdi-eye
            </v-icon>
            <!-- <v-chip v-if="isAuthorized( 'Worktime', 'destroy' )" label small outlined title="Supprimer" @click="deleteWorktime(item.wti_id)">
                <v-icon left color='red'>mdi-delete-forever</v-icon>
            </v-chip> -->
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getWorktime )" color="error" icon="mdi-alert">
            Aucune saisie
        </v-alert>
        </template>
    </v-data-table>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :toast="toast" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalWorktime" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Temps de travail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalWorktime', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-worktime class="elevation-2" 
                v-if="modalWorktime" :toast="toast" :switchModal="switchModal"
                :wti_id="wti_id" :act_id="objAction.act_id">
            </edit-worktime>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale   from '../services/dateLocale';
import editWorktime from './Edit_worktime.vue'
import { usrGetters }   from "../store/user.module"
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'edit_worktime',
    props: ['toast', 'table_id', 'objAction', 'routeState'],
    data: function () {
      return {
            isAuthorized: usrGetters.getScopeRight,
            loading: { uploadBtn: false, getWorktime: false },         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Utilisateurs', align: 'left', value: 'usr_firstName'},
            { text: 'Date',         align: 'left', value: 'wti_date'},
            { text: 'Temps',        value: 'wti_duration' },
            { text: 'Validé',        value: 'wti_validated' },
            { text: 'Action',        value: 'wti_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalLayer : false,
            wti_id:0,
            modalWorktime: false,
      }
    },
    mounted: function (){
        this.getWorktime()
    },
    methods: {
        getWorktime(){
            //this.items = []
            this.loading.getWorktime = true
            var searchCriteria = '?per_page=false&extended=withuser&wti_act_id=' + this.objAction.act_id //page='+ this.pagination.page +'&
            //searchCriteria    += '&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id
            this.$http.get( '/worktimes/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getWorktime = false
            })
        },
        openWorktime(id){
            this.wti_id = id
            this.switchModal('modalWorktime', 1)
        },
        editWorktime(id){
            this.wti_id = id
            this.switchModal('modalWorktime', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalLayer', 1)
            }
        },
        deleteWorktime( docid ){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de ce temps', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( 'worktimes/' +  docid).then( () => {
                        this.toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Temps supprimée !' })
                        this.getWorktime()
                    })
                }
            })
        },
        downloadWorktime(doc_id){
            this.$http.customRequest({ method: 'get', url: 'worktimes/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.toast({ color: 'error', text: error })
            })
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalWorktime'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            //if( mode == 0 ){
            //    this.doc_xxx_id = 0
            //}
            if( refresh ){
                this.getWorktime()
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'edit-worktime'   : editWorktime
    }
}
</script>