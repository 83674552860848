<template>
<v-container fluid class="pa-0 ma-0" style="max-width: 800px;">
<v-card style="z-index:1000;" light class="elevation-0">
<v-app-bar flat color="primary" dense dark>
    <v-toolbar-title><v-icon class="mr-3" color="success" v-text="'mdi-cogs'"></v-icon> Filtre général</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text class="pl-0" @click="clearFilter"><v-icon :color="(filterCount) ? 'red' : 'grey lighten-5'" title="Réinitialiser mon filtre">mdi-refresh</v-icon></v-btn>
    <v-btn text :class="(filterCount) ? 'red--text' : ''" @click="saveFilter" title="Enregistrer le filtre">
        <v-icon :color="(filterCount) ? 'green' : 'grey lighten-5'">mdi-content-save</v-icon>
    </v-btn>
</v-app-bar>

<v-layout row wrap class="mt-3 pl-5 pr-5 pb-5">
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Code machine'" ressource="equipments" :listObject.sync="filter.equ_code"
            :listParent.sync="filterSource.equserial" :fields="{ index: 'equ_code', text:'equ_code', search:'equ_code', preprendIcon:'mdi-view-comfy', multiSelect:false }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Matricule'" ressource="equipments" :listObject.sync="filter.equ_serial"
            :listParent.sync="filterSource.equserial" :fields="{ index: 'equ_serial', text:'equ_serial', search:'equ_serial', preprendIcon:'mdi-view-comfy', multiSelect:false }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Marque'" ressource="brands" :listObject.sync="filter.bra_id"
            :listParent.sync="filterSource.brand" :fields="{ index: 'bra_id', text:'bra_name', search:'bra_name', preprendIcon:'mdi-table-merge-cells', prefetch: true }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Modèle'" ressource="brandmodels" :listObject.sync="filter.bmo_id"
            :listParent.sync="filterSource.brandmodel" :fields="{ index: 'bmo_id', text:'bmo_code', search:'bmo_code', preprendIcon:'mdi-tag', prefetch: true }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-autocomplete dense clearable class="ml-2 mr-2" 
            v-model="filter.sta_id" :items="filterSource.sta" label="Statut"
            item-text="sta_label" item-value="sta_id" multiple chips  @change="updateFilter('sta_id')" prepend-icon="mdi-folder-information-outline">
            <template v-slot:selection="data">
                <v-chip :input-value="data.selected" @click:close="removeChip('sta_id', data.item.sta_id)" 
                        close small class="chip--select-multi" :color="data.item.sta_color" >
                    {{ data.item.sta_label.match(/\b(\w)/g).join('.') }}
                </v-chip>
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.sta_label }}
            </template>
            <template v-slot:no-data>
                Aucune donnée
            </template>
        </v-autocomplete>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Prestataire'" ressource="entities" :listObject.sync="filter.ent_id"
            :listParent.sync="filterSource.entities" :fields="{ 
                index: 'ent_id', text:'ent_name', search:'ent_name', preprendIcon:'mdi-account-box-multiple', prefetch: true
            }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 class="pl-2 pr-2">
        <v-menu ref="min_date_plan" v-model="filter.dateMenu.min_date_plan"
            :return-value.sync="filter.min_date_plan"
            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
            offset-y min-width="290px" style="z-index:1500">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field :dense="fieldStyle.dense" :outlined="fieldStyle.outlined"
                    v-bind="attrs" :value="locDateFormat.formatLocale( filter.min_date_plan, 'fr' )"
                    label="Date début" readonly v-on="on">
                    <template v-slot:prepend><v-icon :color="(filter.min_date_plan) ? 'green' : 'red'">mdi-calendar-check</v-icon></template>
                </v-text-field>
            </template>
            <v-date-picker locale="fr-fr" no-title scrollable v-model="filter.min_date_plan">
                <v-btn color="red" @click="$refs.min_date_plan.save(null)"><v-icon>mdi-eraser</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="filter.dateMenu.min_date_plan = false">Annuler</v-btn>
                <v-btn color="primary" @click="$refs.min_date_plan.save( filter.min_date_plan )">OK</v-btn>
            </v-date-picker>
        </v-menu>
    </v-flex>
    <v-flex xs6 class="pl-2 pr-2">
        <v-menu ref="max_date_plan" v-model="filter.dateMenu.max_date_plan"
            :return-value.sync="filter.max_date_plan"
            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
            offset-y min-width="290px" style="z-index:1500">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field :dense="fieldStyle.dense" :outlined="fieldStyle.outlined"
                    v-bind="attrs" :value="locDateFormat.formatLocale( filter.max_date_plan, 'fr' )"
                    label="Date fin" readonly v-on="on" >
                    <template v-slot:prepend><v-icon :color="(filter.max_date_plan) ? 'green' : 'red'">mdi-calendar-check</v-icon></template>
                </v-text-field>
            </template>
            <v-date-picker locale="fr-fr" no-title scrollable v-model="filter.max_date_plan">
                <v-btn color="red" @click="$refs.max_date_plan.save(null)"><v-icon>mdi-eraser</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="filter.dateMenu.max_date_plan = false">Annuler</v-btn>
                <v-btn color="primary" @click="$refs.max_date_plan.save( filter.max_date_plan )">OK</v-btn>
            </v-date-picker>
        </v-menu>
    </v-flex>
</v-layout>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn small rounded color="success" @click="closeWindow()">Appliquer</v-btn>
</v-card-actions>
</v-card>
</v-container>
</template>

<script>
import dateLocale  from '../services/dateLocale';
import { cacheData, cacheGetters, cacheMutations, cacheActions } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_multi.vue'
//import { store, mutations } from "../services/store";
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'view-filter',
    props: ['filterDiff', 'filterCount', 'filterView'],
    data: function () {
        return {
            fieldStyle: { outlined: true, dense: true },
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,
            loading: { getItem: true },
            menu: false,
            filter: {
                bmo_id:     [],
                bra_id:     [],
                equ_code:   [],
                equ_serial: [],
                sta_id:     [],
                tag_id:     [],
                dateMenu: { min_date_plan: null, max_date_plan: null },
                min_date_plan: null,
                max_date_plan: null,
                ent_id:     [],
            },
            filterSource:{
                brandmodel:[],
                brand:     [],
                equcode :  [], //matricule
                equserial :[],
                sta:       [],
                tag:       [],
                entities:  [],
            },
            typSource: [],
            filterTab : [],
            sitTab : [],
            storeFilter: {}
        }
    },
    watch: {
        filterView () {
            this.updateFilter()
        }
    },
    beforeMount: function(){
        this.initFilter()
    },
    mounted:function (){
    },
    methods: {
        getCacheType: cacheGetters.getTypes,
        getCacheStates : cacheGetters.getStates,
        initFilter(){
            for(var prop in this.filter){
                if( cacheData.filter[prop] === undefined ){
                    cacheData.filter[prop] = this.filter[prop]
                }
            }
            this.filter      = cacheGetters.getFilter()
            //Object.assign( this.filter, cacheGetters.getFilter())
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) ) // todoo init if empty

            this.filterSource.sta = this.getCacheStates('GEN')

        },
        updateFilter(){
            var total = 0
            var diff  = []
            for( let prop in this.filter ){
                if( Array.isArray( this.filter[prop] ) ){
                    diff = this.storeFilter[prop].filter(x => this.filter[prop].indexOf(x) < 0 )
                    total += diff.length
                    diff = this.filter[prop].filter(x => this.storeFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                }
            }
            this.$emit('update:filterCount', total)
        },
        removeItem (item) {
            let index = this.filter.equ_typ_id.indexOf(item.sit_id)
            if (index >= 0) { 
                this.filter.equ_typ_id.splice(index, 1)
            }
        },
        saveFilter(){
            cacheMutations.setFilter( this.filter )
            cacheActions.storeCacheToStorage()
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) )
        },
        clearFilter(){
            var filter      = cacheGetters.getFiltrerFromStorage()
            for(var prop in filter){
                if( Object.prototype.toString.call( this.filter[prop] ) === '[object Array]' ) {
                    this.filter[prop] = filter[prop].slice( this.filter[prop] )
                } else if( this.filter[prop] !== undefined ){
                    this.filter[prop] = filter[prop]
                }
            }
            this.updateFilter()
        },
        closeWindow(){
            filterUpdateService.sendFilter(this.filter)
            this.$emit('update:filterView', false)
        },
        removeChip (fromFilter, id) {
            const index = this.filter[fromFilter].indexOf(id)
            if (index >= 0) this.filter[fromFilter].splice(index, 1)
        },
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>