<template>
<v-container fluid class="elevation-0 pa-0">
    <v-layout wrap class="pa-2" style="background-color:white;">
        <v-flex xs5 style="border-right:1px solid #E0E0E0;">
            <v-list dense>
                <template v-for="(itemLeft, indexLeft) in blockObj.info.data['left']">
                <v-list-item :key="indexLeft" dense class="min-item-height">
                    <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title>{{ (itemLeft.switch) ? itemLeft.text2 : itemLeft.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>
                            {{ (itemLeft.switch) ? itemLeft.value2 : itemLeft.value }}
                            <v-icon v-show="itemLeft.switch !== undefined" @click="itemLeft.switch = !itemLeft.switch">mdi-swap-vertical</v-icon>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs4 >
            <v-list dense>
                <template v-for="(itemRight, indexRight) in blockObj.info.data['right']">
                <v-list-item :key="indexRight" dense class="min-item-height">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemRight.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ itemRight.value }}</v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs3>
            <v-list dense>
                <template v-for="(itemInfo, indexInfo) in blockObj.info.data['info']">
                <v-list-item :key="indexInfo" class="min-item-height">
                    <!-- <v-list-item-content><v-list-item-title>{{ itemInfo.text }}</v-list-item-title></v-list-item-content> -->
                    <v-list-item-action v-if="itemInfo.mod == 'chip'" class="mr-0">
                        <v-chip v-if="itemInfo.value" color="primary" small label outlined>{{ itemInfo.value }}</v-chip>
                        <v-icon v-else light :title="itemInfo.title">mdi-information-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-action v-else><v-list-item-action-text>{{ itemInfo.value }}</v-list-item-action-text></v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
    </v-layout>
    <!-- Associative Tab -->
    <v-card fluid class="mt-0 pa-0 elevation-0" color="#eeeeee">
        <v-divider></v-divider>
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            <v-tab ><v-icon class="mr-1">mdi-file</v-icon><span class="hidden-sm-and-down">Fiche</span></v-tab>
            <v-tab v-if="objAction.act_id > 0"><v-icon class="mr-1">mdi-list-status</v-icon><span class="hidden-sm-and-down">Travaux</span></v-tab>
            <v-tab v-if="objAction.act_id > 0"><v-icon class="mr-1">mdi-clock-time-nine-outline</v-icon><span class="hidden-sm-and-down">Temps</span></v-tab>
            <v-tab v-if="objAction.act_id > 0"><v-icon class="mr-1">mdi-paperclip</v-icon><span class="hidden-sm-and-down">Documents</span></v-tab>
            <v-tab v-if="objAction.act_id > 0" v-show="isAuthorized( 'Event', 'index' )"><v-icon class="mr-1">mdi-calendar-outline</v-icon><span class="hidden-sm-and-down">Evenements</span></v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <tab-action v-if="activeTab === 0 && showTab == true" :toast="toast" :objAction.sync="objAction" :objEquipment="objEquipment" :routeState="routeState" class="pa-3"></tab-action>
        <tab-task   v-if="activeTab === 1 && showTab == true" :toast="toast" :objAction.sync="objAction" :routeState="routeState" class="pa-3"></tab-task>
        <tab-wtime  v-if="activeTab === 2 && showTab == true" :toast="toast" :objAction.sync="objAction"    :table_id="'act'" :routeState="routeState" class="pa-3"></tab-wtime>
        <tab-doc    v-if="activeTab === 3 && showTab == true" :toast="toast" :doc_xxx_id="objAction.act_id" :table_id="'act'" :routeState="routeState" class="pa-3"></tab-doc>
        <tab-event  v-if="activeTab === 4 && showTab == true" :toast="toast" :eve_xxx_id="objAction.act_id" :table_id="'act'" :routeState="routeState" :title="'Evenements'" class="pa-3"></tab-event>

        <v-card-actions class="pr-3">
            <v-spacer></v-spacer>
            <v-btn v-show="isAuthorized( 'Action', 'updateValidated' ) || objAction.act_sta_id !== 'GEN03'" v-if="(!routeState.edit) && activeTab < 2"  :color="'green'" @click="saveAction()" :disabled="loading.saveAction">Enregistrer</v-btn>
            <v-btn v-show="objAction.act_sta_id == 'GEN01'" v-if="(!routeState.edit) && activeTab < 1 && objAction.act_id"  color="orange" @click="closeAction()">Cloturer</v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import dateLocale      from '../services/dateLocale';
import actionTab       from './Tab_action.vue'
import taskTab         from './Tab_task.vue'
import documentTab     from './Tab_document.vue'
import worktimeTab     from './Tab_worktime.vue'
import eventTab        from './Tab_event.vue'
import { usrGetters }  from "../store/user.module"
import { actObj }      from '../mixins/act_obj.js'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'view_sheet',
    props: ['toast', 'link', 'routeState', 'objEquipment'],
    mixins: [ actObj ],
    data: function () {
        return {
            equLoaded: false,
            loading: { getSite: false, saveAction:false },
            locDateFormat: dateLocale, // Format de date
            activeTab: 0, showTab: false,
            blockObj: {
                info : { data: { 'left': [], 'right': [], 'info': [] }, 'left': '', 'right': '', 'info': '' },
            },
            card_obj: {},
            isAuthorized: usrGetters.getScopeRight,
            actionItem: { act_id: 0 },
            actionItems: [],
        }
    },
    mounted: function (){
        if( this.$route.params.equ_id !== undefined && this.$route.params.equ_id > 0 ){
            this.objEquipment.equ_id  = this.$route.params.equ_id
            this.objAction.act_equ_id = this.$route.params.equ_id
        }
        if( this.$route.params.act_id !== undefined && this.$route.params.act_id > 0 ){
            this.objAction.act_id = this.$route.params.act_id
        }
        this.getItemsData()
    },
    methods: {
        async getItemsData(){
            let usage = (this.objEquipment.equ_usage_distance !== 0) ? this.objEquipment.equ_usage_distance : this.objEquipment.equ_usage_hour

            this.blockObj.info.data['left'].push(  { text: 'Matricule',   value: this.objEquipment.equ_code } )
            this.blockObj.info.data['left'].push(  { text: 'Groupe',      value: this.objEquipment._info.group.grp_name })
            this.blockObj.info.data['left'].push(  { text: 'Catégorie',   value: this.objEquipment._info.category.cat_name })
            this.blockObj.info.data['left'].push(  { text: 'Aquisition',  value: this.getCacheType('TRA', this.objEquipment.equ_typ_id_trade).typ_name })
            this.blockObj.info.data['left'].push(  { text: 'Propriétaire',value: this.objEquipment._info.entity.ent_name })
            this.blockObj.info.data['left'].push(  { text: 'Dépôt',       value: this.objEquipment._info.store.str_name })
            
            //this.blockObj.info.data['right'].push( { text: 'Dépôt',      value: '' })
            this.blockObj.info.data['right'].push( { text: 'Immat.',     value: this.objEquipment.equ_customfields.equ_registration })            
            this.blockObj.info.data['right'].push( { text: 'Marque',     value: this.objEquipment._info.brand.bra_name })
            this.blockObj.info.data['right'].push( { text: 'Modèle',     value: this.objEquipment._info.brandmodel.bmo_code } )
            this.blockObj.info.data['right'].push( { text: 'Index',      value: usage })
            this.blockObj.info.data['right'].push( { text: 'Code',       value: this.objEquipment.equ_code })
            this.blockObj.info.data['right'].push( { text: 'Agence',     value: this.objEquipment._info.agency.agc_name })

            this.blockObj.info.data['info'].push(  { 
                text: 'Creation', value: this.locDateFormat.toLocale(this.objEquipment.equ_created_at, { year: 'numeric', month: 'numeric', day: 'numeric' }), 
                mod:'chip', icon: 'mdi-account-circle-outline'
            })
            this.blockObj.info.data['info'].push( {
                text: 'Energie',value: this.getCacheType('ENY', this.objEquipment._info.brandmodel.bmo_typ_id_energy).typ_name, 
                mod:'chip', icon: 'mdi-lightning-bolt'
            })
            this.blockObj.info.data['info'].push( {
                text: 'Réparation', value: this.locDateFormat.toLocale(this.objEquipment.equ_repair_at, { year: 'numeric', month: 'numeric', day: 'numeric' }), 
                mod:'chip', icon: 'mdi-car-wrench'
            })
            this.blockObj.info.data['info'].push( {
                text: 'N°Série', value: this.objEquipment.equ_serial, 
                mod:'chip', icon: 'mdi-barcode'
            })

            await this.getActionShow( 'entity,category,brandmodel' )
            this.showTab = true
        },
        async saveAction(){
            this.loading.saveAction = true
            this.$eventBus.$emit('saveaction')
            if( this.objEquipment['_info']['user'] && this.objEquipment['_info']['user'][0] ){
                this.objAction.act_usr_id_attrib = this.objEquipment['_info']['user'][0]['usr_id']
            }
            if(this.objAction.act_id){
                await this.putActionUpdate()
            } else{
                await this.postActionStore()
            }
            this.objEquipment.equ_usage_distance = parseInt(this.objAction.act_usage_distance) || 0
            this.objEquipment.equ_usage_hour     = parseInt(this.objAction.act_usage_hour) || 0
            await this.getItemsData()

            this.toast({ color: 'success', text: 'Fiche enregistrée !' })
            this.loading.saveAction = false
            this.$router.push({ path: this.$route.matched[1].path + '/sheet/' + this.objEquipment.equ_id })
        },
        async closeAction(){
            let message = 'Confirmez vous cette action ?';
            if( !this.objAction.act_date_end || this.objAction.act_date_end.substring(0, 4) == '0000' ){
                this.objAction.act_date_end = this.locDateFormat.getToday('en')
                message =  message + "<br /><br />Attention la date de clôture sera automatique: " + this.locDateFormat.getToday('fr');
            }
            this.$root.$confirm('Clotuer la fiche', message, { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.objAction.act_sta_id = 'GEN03'
                    this.putActionUpdate()
                    this.toast({ color: 'success', text: 'Fiche clôturée !' })
                    this.$router.push({ path: this.$route.matched[1].path + '/sheet/' + this.objEquipment.equ_id })
                }
            })
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        'tab-action': actionTab,
        'tab-task': taskTab,
        'tab-doc' : documentTab,
        'tab-event': eventTab,
        'tab-wtime' : worktimeTab,
    }
}
</script>

<style lang="css">
/* Personnalisation code */
.min-item-height {
  min-height: 10px !important;
}

</style>