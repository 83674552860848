var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"flat":"","color":"barrinfo"}},[_c('v-toolbar-title',{class:_vm.contextStyle.titleClass},[_c('v-icon',{class:_vm.contextStyle.titleIcon,attrs:{"large":"","color":_vm.contextStyle.titleColor}},[_vm._v(_vm._s(_vm.contextStyle.icon))]),_c('span',[_vm._v(_vm._s(_vm.contextStyle.title))])],1),_c('v-spacer')],1),_c('v-card',{staticClass:"ma-4 rounded-lg"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"footer-props.items-per-page-text":"Lignes par page","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.doc_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheState('GEN', item.doc_sta_id).sta_color}},[_vm._v(_vm._s(_vm.getCacheState('GEN', item.doc_sta_id).sta_label))])]}},{key:"item.doc_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheType('DOC', item.doc_typ_id).typ_color}},[_vm._v(_vm._s(_vm.getCacheType('DOC', item.doc_typ_id).typ_name))])]}},{key:"item.doc_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","title":item.equ_id},on:{"click":function($event){return _vm.downloadDocument(item.doc_id)}}},[_c('v-icon',{attrs:{"left":"","color":"grey"}},[_vm._v("mdi-file-download")])],1)]}},{key:"item.doc_created_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.formatLocale( item.doc_created_at, 'fr' )))])]}},{key:"item.doc_xxx_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":'#/documents/sheet/' + item.act_equ_id + '/action/' + item.doc_xxx_id,"small":"","rounded":"","outlined":"","color":"primary","dark":""}},[_c('v-icon',[_vm._v("mdi-folder-open-outline")]),_vm._v(" "+_vm._s(_vm.originName[item.doc_table_id].name)+" ")],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-2",attrs:{"value":true,"color":"error","icon":"mdi-alert"}},[_vm._v("Aucun document")])],1)],2)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"text-left pl-8",attrs:{"xs6":""}},[_c('span',[_vm._v(_vm._s(_vm.pagination.totalItems)+" résultats")])]),_c('v-flex',{staticClass:"text-right pr-0",attrs:{"xs6":""}},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount,"total-visible":7},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }