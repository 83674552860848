<template>
<v-container fluid class="pa-0" style="height: 100%;">
  <v-toolbar class="elevation-0" flat color="barrinfo">
      <v-toolbar-title :class="contextStyle.titleClass">
          <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
          <span>{{ contextStyle.title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn small outlined class="mr-4" color="orange darken-2" @click="showHideUnplanned()">Plannifier</v-btn> -->
      <v-btn small outlined class="mr-4" color="grey darken-2" @click="focus = ''" >Aujourd'hui</v-btn>
      <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small outlined color="grey darken-2" v-bind="attrs" v-on="on" >
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(item, index) in types">
            <v-list-item @click="type = item.value" :key="index">
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn small outlined class="ml-2" color="grey darken-2" @click="getEvents(currentDate.start, currentDate.end)" ><v-icon>mdi-refresh</v-icon></v-btn>
  </v-toolbar>
  <div class="float-container pl-3 pr-3">
      <div :class="'float-unplanned' + unplannedState">
        <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                    hide-details v-model="searchTask" class="pa-2 rounded-xl">
        </v-text-field>
        <v-list dense class="rounded-lg mr-2" v-if="unplannedTasks.length">
          <v-list-item v-for="(item, i) in unplannedTasks" :key="i">
            <v-list-item-content>
            <v-chip label 
              draggable @dragstart="storeUnplanned(item, i)" @dragend="unplannedTasksLeave()" @dragover.prevent @dragenter.prevent>
              <v-icon left>mdi-traffic-cone</v-icon>{{item.tre_id}} - {{item.tre_address}}
            </v-chip>
            </v-list-item-content>
        </v-list-item>
        </v-list>
        <v-alert v-else-if="searchTask.length" color="warning" class="ma-2 rounded-lg" icon="mdi-alert">Aucune résultat</v-alert>
      </div>
      <div :class="'float-calendar' + unplannedState">
      <v-card class="pt-3 pb-3 ma-0 mt-2 mb-5 rounded-lg" ref="calBox">
        <v-sheet tile height="54" class="d-flex">
          <v-toolbar dense flat>
            <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()"><v-icon>mdi-chevron-right</v-icon></v-btn>        
            <!-- <v-select v-model="type" :items="types" dense outlined hide-details class="ma-2" label="type"></v-select> -->
          </v-toolbar>
        </v-sheet>
        <v-progress-linear v-if="loading.getItem" indeterminate color="primary"></v-progress-linear>
        <v-sheet :height="calHeight" class="rounded-lg">
          <v-calendar 
            ref="calendar" v-model="focus" :weekdays="weekday" :type="type" :events="events" :event-overlap-mode="mode" 
            :event-overlap-threshold="30" :event-color="getEventColor" @change="getEvents" @click:event="showEvent"
            @click:date="createAddEvent" @click:more="showMoreEvent"
            @mousedown:event="startDrag"
            @mousedown:day="startTime"
            @mousemove:day="mouseMove"
            @mouseup:day="endDrag"
            @mouseleave.native="cancelDrag"
            @mouseenter:day="dragFromOutside"
            > <!--  @drop="unplannedTasksLeave($event, 1)" @dragover.prevent @dragenter.prevent-->
            <template v-slot:event="{ event, timed, eventSummary }" >
                  <v-icon v-if="event.eve_date_due" color="red" small>mdi-calendar-clock</v-icon>
                  {{ event.name }}
                  <div :class="'v-event-draggable' + (itemToPlan) ? 'cell-drag-hover' : ''" v-html="eventSummary()"></div>
                  <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
            </template>
          </v-calendar>
          <v-menu transition="scale-transition" bottom origin="center center"
            v-model="selectedMore" :close-on-content-click="false" :activator="selectedElement" style="z-index:2066;">
              <v-card color="grey lighten-4" min-width="350px" flat >
                <v-toolbar dark dense color="primary">
                  {{ locDateFormat.toLocale(moreDate, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </v-toolbar>
                <v-card-text class="pa-0">
                  <v-list dense>
                  <v-list-item v-for="(item, index) in eventDay" :key="index" dense>
                      <v-list-item-content class="ma-0 pa-0">
                        <v-chip class="ma-0" small @click="selectedMore = false" :href="'/#/planning/sheet/' + item.eve_equ_id + '/action/' + item.act_id + ''">
                        <v-icon v-if="item.eve_date_due" color="white" small>mdi-calendar-clock</v-icon>
                          {{ ( !item.tre_address && !item.tre_address ) ? '#' + item.tre_id : item.tre_address + ' ' + item.tre_loc_no  }}
                        </v-chip>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions></v-card-actions>
              </v-card>
            </v-menu>
        </v-sheet>
      </v-card>
    </div>
  </div>
  <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
      <v-card color="grey lighten-4" min-width="350px" flat >
      <v-toolbar color="primary" dark class="elevation-1" height="50px">
          <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
          <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="mt-2">
          <edit-event class="elevation-2" 
              v-if="modalEvent" :toast="toast" :switchModal="switchModal"
              :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
              :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
              :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates"
              :start="selectionEvent.start">
          </edit-event>
      </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="modalListEvent" max-width="900" style="z-index:2066;">

  </v-dialog>
</v-container>
</template>

<script>
import dateLocale        from '../services/dateLocale';
import { eveObj } from '../mixins/eve_obj.js'
import { actObj } from '../mixins/act_obj.js'
import { cacheData, cacheGetters } from "../store/cache.module"
import editEvent  from '../components/Edit_event.vue'
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'calendar',
    mixins: [ eveObj, actObj ],
    props: ['toast', 'contextStyle'],
    data: () => ({
        loading: { getItem: true, getUnplanned: false },
        locDateFormat: dateLocale, // Format de date
        loadEvent: false,
        unplannedState : '',
        type: 'month',
        typeToLabel: { month: 'Mois', week: 'Semaine', day: 'Jour', '4day': '4 Jours', },
        types: [{ text: 'Mois', value: 'month' }, { text: 'Semaine', value: 'week' }, { text: 'Jour', value: 'day' }, { text: '4 Jours', value: '4day' },],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [1, 2, 3, 4, 5, 6, 0],
        weekdays: [
        { text: 'Lun - Dim', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Dim - Sam', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Lun - Ven', value: [1, 2, 3, 4, 5] },
        ],
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        focus: '',
        events: [],
        eventDay: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        modalEvent    : false,
        modalListEvent: false,
        selectionEvent: { xxxId: [], eveId:0, eveTable: "cad", eveType: "ACT", title:"Actions", cooridnates:null, start:null },
        selectedEvent: {},
        selectedElement: null,
        selectedMore: false,
        moreDate: '',
        drawerPlan: true,
        searchTask: '',
        timeOutHandle: 'off',
        searchTempo: undefined,
        unplannedTasks: [],
        itemToPlan: null,
        currentDate: { start: null, end:null }
    }),
    watch: {
        searchTask (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                this.loadUnplannedTask(this.searchWord)
                this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        user(){
            return cacheData.user
        },
        calHeight(){
        let height = (this.$refs.calBox && this.$refs.calBox.clientHeight) ? this.$refs.calBox.clientHeight : 750
        return height
        }
    },
    created(){
        this.subFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.loadEvents()
            }
        })
    },
    mounted: function (){

    },
    methods: {
        getEvents( { start, end } ){
        this.currentDate.start = start
        this.currentDate.end   = end
        clearTimeout( this.loadEvent )
        this.loading.getItem = true
        this.loadEvent = setTimeout( () => {
            this.loadEvents( { start, end } )
        }, 500)
        },
        loadEvents () {
        let start = this.currentDate.start
        let end = this.currentDate.end  
        this.loading.getItem = true
        this.events  = []
        const events = []
        const types  = this.getCacheType()

        this.getActionIndex( 'extended=withequipment,withcategory,withbrand,withbrandmodel,withgroup&include=equipment&min_act_date_start=' + start.date + '&max_act_date_start=' + end.date ).then( () => {
            this.listAction.forEach( (obj) => {
            let type = obj.act_typ_id.substring(0, 3)
            //obj.tre_address = (obj.tre_address == null) ? '' : obj.tre_address.trim()
            //obj.tre_loc_no  = (obj.tre_loc_no == null) ? '' : obj.tre_loc_no.trim()
            let label = obj.cat_name 

            events.push({
                name: label,
                start: new Date(obj.act_date_start),
                //end: second,
                color: types[type][obj.act_typ_id]['typ_color'], //this.colors[this.rnd(0, this.colors.length - 1)],
                //timed: !allDay,
                eve_id       : obj.act_id,
                eve_equ_id   : obj.equ_id,
                eve_table_id : 'act',
                eve_typ_id   : obj.act_typ_id,
                eve_date_end : obj.act_date_end,
                title        : 'Tache'
            })
            });
            this.loading.getItem = false
        })
        this.events = events
        },
        loadUnplannedTask(){
        this.unplannedTasks = []
        this.getTaskIndex( 1, false, '&act_sta_id=GEN01&act_typ_id=TSK01&per_page=false' ).then( () => {
            this.listTask.forEach( (obj) => {
            this.unplannedTasks.push(obj)
            })
        })
        },
        unplannedTasksLeave(){
        setTimeout( () => {
            this.itemToPlan = null
        }, 600)
        },
        storeUnplanned(item, index){
        item['key'] = index
        this.itemToPlan = item
        },
        dragFromOutside(e){
        if( this.itemToPlan !== null && this.itemToPlan ){
            const types  = this.getCacheType()
            let type = this.itemToPlan.act_typ_id.substring(0, 3)
            this.itemToPlan.tre_address = ( this.itemToPlan.tre_address ) ? '' : this.itemToPlan.tre_address
            let label = ( !this.itemToPlan.tre_address.length || !this.itemToPlan.tre_address.length ) ? '#' + this.itemToPlan.tre_id : this.itemToPlan.tre_address + ' ' + this.itemToPlan.tre_loc_no 
            this.itemToPlan.act_typ_id = "TSK02"
            this.events.push({
            name: label,
            start: new Date(e.date),
            //end: second,
            color: types[type][this.itemToPlan.act_typ_id]['typ_color'], //this.colors[this.rnd(0, this.colors.length - 1)],
            //timed: !allDay,
            eve_id       : this.itemToPlan.act_id,
            eve_table_id : 'tsk',
            eve_typ_id   : this.itemToPlan.act_typ_id,
            eve_date_due : this.itemToPlan.act_date_due,
            title        : 'Tache'
            })
            this.itemToPlan.act_date_plan = e.date
            this.putTaskStore(this.itemToPlan).then( () => {
            this.unplannedTasks.splice(this.itemToPlan.key, 1)
            this.itemToPlan = null
            })
        }
        },
        getEventColor (event) {
        return event.color
        },
        rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
        },
        switchModal(name, mode){
            name    = name || 'dialogObj'
            mode    = mode || false
            //refresh = refresh || false
            this[name] = mode
            if( !mode ){
            this.getEvents()
            }
        },
        showEvent(event){
        this.selectionEvent.start    = null
        this.selectionEvent.xxxId    = this.user.usr_pro_id
        this.selectionEvent.eveId    = event.event.eve_id
        this.selectionEvent.eveTable = event.event.eve_table_id
        this.selectionEvent.eveType  = event.event.eve_typ_id.substring(0, 3)
        this.selectionEvent.title    = 'Alerte'

        this.$router.push({ path: '/planning/sheet/' + event.event.eve_equ_id + '/action/' + event.event.eve_id + '' })
        //let Tabtitle = { 'ACT': 'Action', 'ALT': 'Alerte' }
        //if( Tabtitle[this.selectionEvent.eveType] ){
        //  this.selectionEvent.title    = Tabtitle[this.selectionEvent.eveType]
        //}
    //
        //this.switchModal('modalEvent', 1)
        },
        createAddEvent(info){
        this.selectionEvent.start    = info.date
        this.selectionEvent.xxxId    = this.user.usr_pro_id
        this.selectionEvent.eveId    = 0
        this.selectionEvent.eveTable = 'pro'
        this.selectionEvent.eveType  = 'ACT'
        this.selectionEvent.title    = 'Action'
        this.switchModal('modalEvent', 1)
        },
        showMoreEvent(info){
        this.eventDay = []
        this.getTaskIndex( '?act_sta_id=GEN01&per_page=false&act_date_plan=' + info.date ).then( () => {
            this.eventDay = this.listTask
        })
        const open = () => {
            this.moreDate        = info.date
            this.selectedElement = info.nativeEvent.target
            setTimeout(() => this.selectedMore = true, 10)
        }
        if (this.selectedMore) {
            this.selectedMore = false
            setTimeout(open, 10)
        } else {
            open()
        }
        },
        startDrag ({ event }) {
        if (event) {
            this.dragEvent = event
            this.dragTime = null
            this.extendOriginal = null
        }
        },
        startTime (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
            const start = this.dragEvent.start
            this.dragTime = mouse - start
        } else {
            this.createStart = this.roundTime(mouse)
            this.createEvent = {
            name: `Event #${this.events.length}`,
            color: this.rndElement(this.colors),
            start: this.createStart,
            end: this.createStart,
            timed: true,
            }

            //this.events.push(this.createEvent)
        }
        },
        extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
        },
        mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
            const start = this.dragEvent.start
            const end = this.dragEvent.end
            const duration = end - start
            const newStartTime = mouse - this.dragTime
            const newStart = this.roundTime(newStartTime)
            const newEnd = newStart + duration

            this.dragEvent.start = newStart
            this.dragEvent.end = newEnd
        } 
        },
        endDrag (e) {
        if( this.dragEvent ){
            this.dragEvent.act_date_plan = e.date
            this.dragEvent.act_id = this.dragEvent.eve_id
            this.dragEvent.act_sta_id = 'GEN01'
            //this.putActionUpdate(this.dragEvent)
            this.dragTime = null
            this.dragEvent = null
            this.createEvent = null
            this.createStart = null
            this.extendOriginal = null
        }
        },
        cancelDrag () {
        if (this.createEvent) {
            if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
            } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
                this.events.splice(i, 1)
            }
            }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
        },
        roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        rndElement (arr) {
            return arr[this.rnd(0, arr.length - 1)]
        },
        showHideUnplanned(){
        if( this.unplannedState == '' ){
            this.unplannedState = '-active'
        } else {
            this.unplannedState = ''
        }
        },
        getCacheType: cacheGetters.getTypes,
    },
    beforeDestroy() {
        this.subFilter.unsubscribe()
    },
    components: {
        'edit-event'   : editEvent
    }
}
</script>

<style lang="scss">
.v-calendar-weekly__day-label button {
  height: 20px !important;
}


.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.float-container {
    border: 0px;
}

.float-unplanned {
    width: 0%;
    float: left;
    border: 0px;
    transition: width, 0.5s linear 0s;
}
.float-calendar {
    width: 100%;
    float: left;
    border: 0px;
    transition: width, 0.5s linear 0s;
}
.float-unplanned-active {
    width: 20%;
    float: left;
    border: 0px;
    transition: width, 0.5s linear 0s;
}
.float-calendar-active {
    width: 80%;
    float: left;
    border: 0px;
    transition: width, 0.5s linear 0s;
}
.v-calendar-weekly__day:hover{
  /*border: 1px solid green;*/
  background-color: #E3F2FD;
}
</style>